<template>
  <v-app :class="this.store.state.global.bodyClass">
    <Sidebar />
    <div class="container">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import { useStore } from "vuex";
export default {
  name: "App",
  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  data() {
    return {};
  },

  components: {
    Sidebar,
  },

  created() {},

  mounted() {
    if (this.store.state.global.userData.roles.includes("ROLE_OPERATORE")) {
      window.location.href = "/scansiona-colli";
    }
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  height: 100%;
  width: calc(100% - 78px);
  left: 78px;
}

@media screen and (max-width: 420px) {
  .container {
    margin-top: 70px;
    width: 100%;
    left: 0;
  }
}

.formOpen {
  height: 100vh;
  overflow: hidden;
}
</style>
