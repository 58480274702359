<template>
    <div>
        <v-card title="MODIFICA UTENTE" v-if="this.utente">
            <v-card-text>
                <v-container>
                    <v-text-field
                        style="width: 300px"
                        label="EMAIL"
                        required
                        variant="outlined"
                        v-model="this.email"
                    ></v-text-field>
                    <v-text-field
                        style="width: 300px"
                        label="NOME"
                        required
                        variant="outlined"
                        v-model="this.nomeUtente"
                    ></v-text-field>
                    <v-text-field
                        style="width: 300px"
                        label="COGNOME"
                        required
                        variant="outlined"
                        v-model="this.cognomeUtente"
                    ></v-text-field>
                    <v-select
                        :items="['ADMIN', 'OPERATORE']"
                        label="RUOLO"
                        variant="outlined"
                        v-model="this.ruolo"
                        required
                    ></v-select>
                </v-container>
                <small style="color: red">{{ this.errorMsg }}</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="closeForm"
                >
                    CHIUDI
                </v-btn>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="editUtente"
                    class="bg-green"
                >
                    INVIA
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    export default {
        name: "EditUtenteForm",
        props: {
            utente: {
                type: Object,
            },
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        data() {
            return {
                ruolo: null,
                nomeUtente: "",
                cognomeUtente: "",
                email: "",
                errorMsg: "",
            }
        },

        mounted() {
            console.log(this.utente);
            this.email = this.utente.email
            this.nomeUtente = this.utente.firstName
            this.cognomeUtente = this.utente.lastName
            if(this.utente.ruolo === "ROLE_OPERATORE") {
                this.ruolo = "OPERATORE"
            } else if(this.utente.ruolo === "ROLE_ADMIN") {
                this.ruolo = "ADMIN"
            }
        },

        methods: {
            closeForm() {
                this.errorMsg = ''
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeEditUtenteForm')
            },
            editUtente() {
                if(this.nomeUtente !== '' && this.ruolo !== null && this.email !== '' && this.cognomeUtente !== ''){
                    let newRole = ""
                    if(this.ruolo === "OPERATORE") {
                        newRole = "ROLE_OPERATORE"
                    } else if(this.ruolo === "ADMIN") {
                        newRole = "ROLE_ADMIN"
                    }
                    const newUtente = {
                        email: this.email,
                        firstName: this.nomeUtente,
                        lastName: this.cognomeUtente,
                        ruolo: newRole,
                    }
                    this.$emit('edit-utente', newUtente)
                    this.errorMsg = ''
                } else {
                    this.errorMsg = 'Compila tutti i campi'
                }
            }
        },

        watch: {
            utente: function(newVal) {
                console.log(newVal, "newVal");
                this.email = newVal.email
                this.nomeUtente = newVal.firstName
                this.cognomeUtente = newVal.lastName
                if(newVal.ruolo === "ROLE_OPERATORE") {
                    this.ruolo = "OPERATORE"
                } else if(newVal.ruolo === "ROLE_ADMIN") {
                    this.ruolo = "ADMIN"
                }
            }
        }
    }
</script>

<style scoped>

    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .v-card-title {
        text-align: center !important;
    }
    
    .v-list-item--active {
        background-color: #2196f3;
        color: white;
    }
    
</style>