<template>
  <div>
    <v-card title="SCANSIONA COLLI" width="400px">
      <Icon
        v-if="this.loading"
        icon="line-md:loading-loop"
        style="color: black"
      />
      <div
        v-else
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
        "
      >
        <v-select
          :items="['FEDEX', 'DHL', 'ALTRO']"
          density="comfortable"
          label="CORRIERE"
          variant="outlined"
          v-model="this.corriere"
          hide-details
          style="width: 300px; flex: none"
        ></v-select>
        <v-textarea
          v-model="this.store.state.colliUscitaEntrata.awbsUscita"
          label="AWB"
          rows="10"
          no-resize
          style="width: 300px"
          hide-details
          variant="outlined"
        ></v-textarea>
        <div v-if="this.bancaliAperti.length > 0">
          <v-btn v-for="bancale in this.bancaliAperti"
            >CHIUDI BANCALE {{ bancale.corriere.toUpperCase() }} N°
            {{ bancale.codicePallet }}</v-btn
          >
        </div>
        <div v-else>NON HAI BANCALI APERTI</div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text="CHIUDI"
          @click="closeScansionaColliForm"
          class="bg-red"
          variant="outlined"
        ></v-btn>
        <v-btn variant="outlined" class="bg-green" @click="inviaScansioni">
          INVIA SCANSIONI
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
const axios = require("axios");
import Swal from "sweetalert2";
const { DateTime } = require("luxon");
export default {
  name: "ScansionaColliUscitaForm",

  data() {
    return {
      bancaliAperti: [],
      loading: false,
      corriere: "DHL",
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  methods: {
    async findAllColliAperti() {
      const apiUrl = `${this.$apiBaseUrl}/pallet/findAllPallet`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        this.bancaliAperti = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei bancali aperti`,
          icon: "error",
        });
      }
    },
    async inviaScansioni() {
      let awbsArray =
        this.store.state.colliUscitaEntrata.awbsUscita.split("\n");
      let errors = [];
      // Elimino eventuali spazi
      awbsArray = awbsArray.map((item) => item.trim());
      awbsArray = awbsArray.filter((linea) => linea.trim() !== "");
      if (awbsArray.length > 0) {
        this.loading = true;
        for (var awb of awbsArray) {
          console.log(awb);
          let data = {
            orderNumber: null,
            customerTrackingNumber: awb,
            supplierTrackingNumber: null,
            supplierName: this.corriere.toLowerCase(),
            printerId: null,
            order: true,
          };

          const apiUrl = `${this.$apiBaseUrl}/pallet/addPallet`;
          const bearerToken = this.$keycloak.token;
          console.log(bearerToken);

          const config = {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          };

          try {
            const response = await axios.post(apiUrl, data, config);
            console.log("Response:", response.data);
          } catch (error) {
            console.error("Error:", error.message);
            errors.push(awb);
          }
        }
        this.store.dispatch("changeAwbsUscita", "");
        if (errors.length > 0) {
          Swal.fire({
            title: "ERRORE",
            text: `C'è stato un errore nell'invio delle scansioni: ${errors}`,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "SCANSIONI RIUSCITE",
            text: `Le scansioni sono state inviate.`,
            icon: "success",
          });
        }
        this.findAllColliAperti();
      }
    },
    closeScansionaColliForm() {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("closeScansionaColliUscitaForm");
    },
  },

  mounted() {
    this.findAllColliAperti();
  },
};
</script>

<style scoped></style>
