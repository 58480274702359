<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    "
  >
    <div style="width: 100%">
      <div class="page-title-container">
        <p class="title-container">GESTIONE BANCALI IN USCITA</p>
      </div>
      <div class="table-container">
        <BancaliUscitaTable @open-details="openDetails" />
      </div>
    </div>
    <div
      id="form-position"
      class="form-container"
      v-if="
        this.store.state.bancali.showContenutoBancaleState ||
        this.store.state.bancali.showModificaBancaleState
      "
    >
      <ContenutoBancale
        v-if="this.store.state.bancali.showContenutoBancaleState"
        :idBancaleSelected="this.store.state.bancali.idBancaleSelezionato"
        :ldv="this.store.state.bancali.ldv"
        :open="this.store.state.bancali.showContenutoBancaleState"
      />
      <ModificaBancale
        v-if="this.store.state.bancali.showModificaBancaleState"
        :bancale="this.store.state.bancali.editingBancale"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import BancaliUscitaTable from "../components/BancaliUscitaTable.vue";
import ContenutoBancale from "../components/ContenutoBancale.vue";
import ModificaBancale from "../components/ModificaBancale.vue";
const axios = require("axios");
export default {
  name: "BancaliUscitaView",

  components: {
    BancaliUscitaTable,
    ContenutoBancale,
    ModificaBancale,
  },

  data() {
    return {};
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  methods: {},
};
</script>

<style scoped>
.page-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  font-size: 2rem;
  letter-spacing: 2px;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

#form-position {
  position: absolute;
  z-index: 4 !important;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
</style>
