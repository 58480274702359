<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    "
  >
    <div style="width: 100%">
      <div class="page-title-container">
        <p class="title-container">GESTIONE RESI</p>
      </div>
      <div class="table-container">
        <ResiTable />
      </div>
    </div>
    <div
      id="form-position"
      class="form-container"
      v-if="this.store.state.resi.showContenutoResoState"
    >
      <ContenutoReso
        :numReso="this.store.state.resi.numResoSelezionato"
        :contenutoReso="this.store.state.resi.contenutoResoSelezionato"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
const axios = require("axios");
import ResiTable from "../components/ResiTable.vue";
import ContenutoReso from "../components/ContenutoReso.vue";
export default {
  name: "ResiView",

  data() {
    return {};
  },

  components: {
    ResiTable,
    ContenutoReso,
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  async created() {},

  methods: {},
};
</script>

<style scoped>
.page-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  font-size: 2rem;
  letter-spacing: 2px;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

#form-position {
  position: absolute;
  z-index: 4 !important;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
</style>
