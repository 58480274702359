<template>
  <div>
    <v-card
      min-width="500px"
      title="MODIFICA BANCALE"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        row-gap: 50px;
        padding-top: 30px;
      "
    >
      <div class="warning-text-container">
        <p class="warning-text">
          SELEZIONA DATA DEL BANCALE:<span class="cod-collo">{{
            this.bancale.codicePallet
          }}</span>
        </p>
      </div>
      <div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            row-gap: 5px;
          "
        >
          <v-dialog width="375px">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                width="220"
                variant="outlined"
                text="DATA SPEDIZIONE"
                height="48px"
              ></v-btn>
            </template>
            <template v-slot:default="{ isActive }">
              <v-card title="SELEZIONA DATA SPEDIZIONE">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-date-picker
                    style="border: 1px solid #d0d0d0 !important"
                    v-model="this.dataSpedizione"
                  ></v-date-picker>
                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text="INVIA"
                    @click="
                      () => {
                        isActive.value = false;
                      }
                    "
                    class="bg-green"
                    variant="outlined"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <small>{{ this.formatDate(dataSpedizione) }}</small>
        </div>
        <small style="color: red">{{ this.errorMessage }}</small>
      </div>
      <v-card-actions style="column-gap: 50px">
        <v-btn
          text="INVIA"
          variant="outlined"
          class="bg-green"
          width="100"
          height="50"
          @click="modificaBancale"
        >
        </v-btn>
        <v-btn
          variant="outlined"
          class="bg-red"
          width="100"
          height="50"
          text="CHIUDI"
          @click="closeModificaBancale"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  name: "ModificaBancale",

  data() {
    return {
      dataSpedizione: null,
      errorMessage: "",
    };
  },

  props: {
    bancale: null,
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  mounted() {
    this.dataSpedizione = new Date(this.bancale.dataSpedizione);
  },

  methods: {
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    async modificaBancale() {
      this.errorMessage = "";

      let bancaleModifica = {
        codicePallet: this.bancale.codicePallet,
        dataSpedizione: this.dataSpedizione,
      };
      const apiUrl = `${this.$apiBaseUrl}/pallet/`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.put(apiUrl, bancaleModifica, config);
        console.log("Response:", response.data);
        this.store.dispatch("changeBodyClass", "");
        this.store.dispatch("closeModificaBancale");
        Swal.fire({
          title: "BANCALE MODIFICATO",
          text: `Il bancale ${this.bancale.codicePallet} è stato modificato.`,
          icon: "success",
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nella modifica del bancale`,
          icon: "error",
        });
      }
    },
    closeModificaBancale() {
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeModificaBancale");
    },
  },
};
</script>

<style scoped>
.warning-text {
  text-align: center;
  font-size: 1.2rem;
  width: 400px;
}

.cod-collo {
  margin-left: 10px;
}

.nome-utente {
  font-weight: 600;
  font-size: 1.5rem;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}
</style>
