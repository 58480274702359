import { createStore } from "vuex";

const globalModule = {
  state: {
    bodyClass: "",
    authorized: false,
    userData: {
      roles: [],
      email: "",
      firstName: "",
      lastName: "",
    },
    editingCollo: null,
    showModificaColloState: false,
    showEliminaColloState: false,
    showScansioniColloState: false,
    scansioni: null,
    ldvScansioni: null,
  },
  mutations: {
    changeAuthorized(state, boolean) {
      state.authorized = boolean;
    },
    changeBodyClass(state, newClass) {
      state.bodyClass = newClass;
    },
    addUserData(state, data) {
      state.userData.email = data.email;
      state.userData.roles = [...data.realm_access.roles];
      state.userData.firstName = data.given_name ? data.given_name : "";
      state.userData.lastName = data.family_name ? data.family_name : "";
    },
    openModificaCollo(state, collo) {
      state.showModificaColloState = true;
      state.editingCollo = collo;
    },
    closeModificaCollo(state) {
      state.showModificaColloState = false;
    },
    openEliminaCollo(state, collo) {
      state.showEliminaColloState = true;
      state.editingCollo = collo;
    },
    closeEliminaCollo(state) {
      state.showEliminaColloState = false;
    },
    openScansioniCollo(state, data) {
      state.showScansioniColloState = true;
      state.ldvScansioni = data[0];
      console.log(data[1]);
      state.scansioni = data[1];
      console.log(state.scansioni);
    },
    closeScansioniCollo(state) {
      state.showScansioniColloState = false;
    },
  },
  actions: {
    changeAuthorized({ commit }, boolean) {
      commit("changeAuthorized", boolean);
    },
    changeBodyClass({ commit }, newClass) {
      commit("changeBodyClass", newClass);
    },
    addUserData({ commit }, data) {
      commit("addUserData", data);
    },
    openModificaCollo({ commit }, collo) {
      commit("openModificaCollo", collo);
    },
    closeModificaCollo({ commit }) {
      commit("closeModificaCollo");
    },
    openEliminaCollo({ commit }, collo) {
      commit("openEliminaCollo", collo);
    },
    closeEliminaCollo({ commit }) {
      commit("closeEliminaCollo");
    },
    openScansioniCollo({ commit }, data) {
      console.log(data);
      commit("openScansioniCollo", data);
    },
    closeScansioniCollo({ commit }) {
      commit("closeScansioniCollo");
    },
  },
};

const utentiModule = {
  state: {
    showCreateUtenteFormState: false,
    showEditUtenteFormState: false,
    showResetPwdState: false,
    showChangeAttivoState: false,
    editingUtente: null,
    utenteAttivoState: "",
  },
  mutations: {
    showCreateUtenteForm(state) {
      state.showCreateUtenteFormState = true;
    },
    closeCreateUtenteForm(state) {
      state.showCreateUtenteFormState = false;
    },
    showEditUtenteForm(state, utente) {
      console.log(utente, "store");
      state.editingUtente = utente;
      console.log(state.editingUtente, "editingUtente");
      state.showEditUtenteFormState = true;
    },
    closeEditUtenteForm(state) {
      state.showEditUtenteFormState = false;
    },
    showResetPwd(state, utente) {
      state.editingUtente = utente;
      state.showResetPwdState = true;
    },
    closeResetPwd(state) {
      state.showResetPwdState = false;
    },
    showChangeAttivo(state, array) {
      state.editingUtente = array[0];
      state.utenteAttivoState = array[1];
      state.showChangeAttivoState = true;
    },
    closeChangeAttivo(state) {
      state.showChangeAttivoState = false;
    },
  },
  actions: {
    showCreateUtenteForm({ commit }) {
      commit("showCreateUtenteForm");
    },
    closeCreateUtenteForm({ commit }) {
      commit("closeCreateUtenteForm");
    },
    showEditUtenteForm({ commit }, utente) {
      commit("showEditUtenteForm", utente);
    },
    closeEditUtenteForm({ commit }) {
      commit("closeEditUtenteForm");
    },
    showResetPwd({ commit }, utente) {
      commit("showResetPwd", utente);
    },
    closeResetPwd({ commit }) {
      commit("closeResetPwd");
    },
    showChangeAttivo({ commit }, array) {
      commit("showChangeAttivo", array);
    },
    closeChangeAttivo({ commit }) {
      commit("closeChangeAttivo");
    },
  },
};

const colliBloccatiModule = {
  state: {
    showSbloccaColloConfirmState: false,
    showBloccaColloFormState: false,
    editingColloBloccato: null,
  },
  mutations: {
    showBloccaColloForm(state) {
      state.showBloccaColloFormState = true;
    },
    closeBloccaColloForm(state) {
      state.showBloccaColloFormState = false;
    },
    showSbloccaColloConfirm(state, collo) {
      state.editingColloBloccato = collo;
      state.showSbloccaColloConfirmState = true;
    },
    closeSbloccaColloConfirm(state) {
      state.showSbloccaColloConfirmState = false;
    },
  },
  actions: {
    showBloccaColloForm({ commit }) {
      commit("showBloccaColloForm");
    },
    closeBloccaColloForm({ commit }) {
      commit("closeBloccaColloForm");
    },
    showSbloccaColloConfirm({ commit }, collo) {
      commit("showSbloccaColloConfirm", collo);
    },
    closeSbloccaColloConfirm({ commit }) {
      commit("closeSbloccaColloConfirm");
    },
  },
};

const colliUscitaEntrataModule = {
  state: {
    showScansionaColliUscitaFormState: false,
    showScansionaColliEntrataFormState: false,
    awbsUscita: "",
    awbsEntrata: "",
  },
  mutations: {
    showScansionaColliUscitaForm(state) {
      state.showScansionaColliUscitaFormState = true;
    },
    closeScansionaColliUscitaForm(state) {
      state.showScansionaColliUscitaFormState = false;
    },
    changeAwbsUscita(state, text) {
      state.awbsUscita = text;
    },
    showScansionaColliEntrataForm(state) {
      state.showScansionaColliEntrataFormState = true;
    },
    closeScansionaColliEntrataForm(state) {
      state.showScansionaColliEntrataFormState = false;
    },
    changeAwbsEntrata(state, text) {
      state.awbsEntrata = text;
    },
  },
  actions: {
    showScansionaColliUscitaForm({ commit }) {
      commit("showScansionaColliUscitaForm");
    },
    closeScansionaColliUscitaForm({ commit }) {
      commit("closeScansionaColliUscitaForm");
    },
    changeAwbsUscita({ commit }, text) {
      commit("changeAwbsUscita", text);
    },
    showScansionaColliEntrataForm({ commit }) {
      commit("showScansionaColliEntrataForm");
    },
    closeScansionaColliEntrataForm({ commit }) {
      commit("closeScansionaColliEntrataForm");
    },
    changeAwbsEntrata({ commit }, text) {
      commit("changeAwbsEntrata", text);
    },
  },
};

const bancaliModule = {
  state: {
    showContenutoBancaleState: false,
    showModificaBancaleState: false,
    idBancaleSelezionato: null,
    editingBancale: null,
    ldv: null,
  },
  mutations: {
    showContenutoBancale(state, array) {
      state.showContenutoBancaleState = true;
      state.ldv = array[1];
      state.idBancaleSelezionato = array[0];
    },
    closeContenutoBancale(state) {
      state.showContenutoBancaleState = false;
    },
    showModificaBancale(state, bancale) {
      state.showModificaBancaleState = true;
      state.editingBancale = bancale;
    },
    closeModificaBancale(state) {
      state.showModificaBancaleState = false;
    },
  },
  actions: {
    showContenutoBancale({ commit }, array) {
      commit("showContenutoBancale", array);
    },
    closeContenutoBancale({ commit }) {
      commit("closeContenutoBancale");
    },
    showModificaBancale({ commit }, bancale) {
      commit("showModificaBancale", bancale);
    },
    closeModificaBancale({ commit }) {
      commit("closeModificaBancale");
    },
  },
};

const ordiniModule = {
  showContenutoOrdineState: false,
  numOrdineSelezionato: null,
  contenutoOrdineSelezionato: null,
  mutations: {
    showContenutoOrdine(state, array) {
      state.showContenutoOrdineState = true;
      state.numOrdineSelezionato = array[1];
      state.contenutoOrdineSelezionato = array[0];
    },
    closeContenutoOrdine(state) {
      state.showContenutoOrdineState = false;
    },
  },
  actions: {
    showContenutoOrdine({ commit }, array) {
      commit("showContenutoOrdine", array);
    },
    closeContenutoOrdine({ commit }) {
      commit("closeContenutoOrdine");
    },
  },
};

const resiModule = {
  showContenutoResoState: false,
  numResoSelezionato: null,
  contenutoResoSelezionato: null,
  mutations: {
    showContenutoReso(state, array) {
      state.showContenutoResoState = true;
      state.numResoSelezionato = array[1];
      state.contenutoResoSelezionato = array[0];
    },
    closeContenutoReso(state) {
      state.showContenutoResoState = false;
    },
  },
  actions: {
    showContenutoReso({ commit }, array) {
      commit("showContenutoReso", array);
    },
    closeContenutoReso({ commit }) {
      commit("closeContenutoReso");
    },
  },
};

const store = createStore({
  modules: {
    global: globalModule,
    utenti: utentiModule,
    colliBloccati: colliBloccatiModule,
    colliUscitaEntrata: colliUscitaEntrataModule,
    bancali: bancaliModule,
    ordini: ordiniModule,
    resi: resiModule,
  },
});

export default store;
