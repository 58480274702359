<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <div class="filters-actions-container">
        <div class="actions">
          <v-btn
            height="48"
            width="200px"
            variant="outlined"
            @click="showScansionaForm"
            >SCANSIONA
          </v-btn>
        </div>
        <form @submit.prevent="searchCollo" class="filters">
          <v-expansion-panels
            style="width: 300px !important; z-index: 3"
            v-model="this.panel"
          >
            <v-expansion-panel
              value="1"
              title="ALTRI FILTRI"
              style="
                width: 300px !important;
                position: absolute;
                border-radius: 5px !important;
                border: thin solid #d0d0d0;
                background-color: white;
              "
              density="comfortable"
            >
              <v-expansion-panel-text style="width: 300px !important">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    row-gap: 5px;
                    width: 100%;
                  "
                >
                  <v-dialog width="375px">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        width="220"
                        variant="outlined"
                        text="DATA SCANSIONE DA"
                        height="48px"
                        style="width: 100%"
                      ></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="SELEZIONA DATA SCANSIONE DA">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-date-picker
                            :max="this.dataScansioneA"
                            style="border: 1px solid #d0d0d0 !important"
                            v-model="this.dataScansioneDa"
                          ></v-date-picker>
                        </div>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text="CANCELLA"
                            @click="this.dataScansioneDa = null"
                            class="bg-red"
                            variant="outlined"
                          ></v-btn>
                          <v-btn
                            text="INVIA"
                            @click="
                              () => {
                                isActive.value = false;
                              }
                            "
                            class="bg-green"
                            variant="outlined"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <small>{{ this.formatDate(dataScansioneDa) }}</small>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    row-gap: 5px;
                    width: 100%;
                  "
                >
                  <v-dialog width="375px">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        style="width: 100%"
                        variant="outlined"
                        text="DATA SCANSIONE A"
                        height="48px"
                      ></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="SELEZIONA DATA SCANSIONE A">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-date-picker
                            :min="this.dataScansioneDa"
                            style="border: 1px solid #d0d0d0 !important"
                            v-model="this.dataScansioneA"
                          ></v-date-picker>
                        </div>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text="CANCELLA"
                            @click="
                              () => {
                                dataScansioneA = null;
                              }
                            "
                            class="bg-red"
                            variant="outlined"
                          ></v-btn>
                          <v-btn
                            text="INVIA"
                            @click="
                              () => {
                                isActive.value = false;
                              }
                            "
                            class="bg-green"
                            variant="outlined"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <small>{{ this.formatDate(dataScansioneA) }}</small>
                </div>
                <v-text-field
                  density="comfortable"
                  variant="outlined"
                  label="CERCA UTENTE"
                  append-inner-icon="mdi-magnify"
                  hide-details
                  style="width: 100% !important"
                  v-model="searchingUtente.text"
                  @input=""
                ></v-text-field>
                <v-switch
                  label="NOTE"
                  v-model="this.hasNote"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
            style="width: 250px !important; z-index: 3"
            v-model="this.panel2"
          >
            <v-expansion-panel
              title="STATI"
              value="1"
              style="
                width: 250px !important;
                position: absolute;
                border-radius: 5px !important;
                border: thin solid #d0d0d0 !important;
              "
              density="comfortable"
            >
              <v-expansion-panel-text style="width: 250px !important">
                <v-switch
                  v-model="this.stati"
                  label="RICEVUTO MAGAZZINO"
                  value="RICEVUTO_MAGAZZINO"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
                <v-switch
                  v-model="this.stati"
                  label="DA RICEVERE"
                  value="DA_RICEVERE"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
                <v-switch
                  v-model="this.stati"
                  label="LAVORATO RETURNS"
                  value="LAVORATO_RETURNS"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
                <v-switch
                  v-model="this.stati"
                  label="LAVORATO ORDERS"
                  value="LAVORATO_ORDERS"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
                <v-switch
                  v-model="this.stati"
                  label="NASCOSTO"
                  value="NASCOSTO"
                  color="#2196f3"
                  hide-details
                  style="
                    width: 200px !important;
                    box-shadow: none !important;
                    border-radius: none !important;
                  "
                ></v-switch>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA LDV"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important"
            v-model="searchingCollo.text"
            :clearable="true"
          ></v-text-field>
          <v-btn
            type="submit"
            icon="mdi-magnify"
            class="bg-blue"
            variant="outlined"
          />
          <v-btn
            variant="outlined"
            height="48"
            width="120"
            class="bg-red"
            @click="resetFiltri"
            >RESET FILTRI</v-btn
          >
        </form>
      </div>
    </div>
    <div style="width: 95%">
      <v-data-table-server
        :loading="loading"
        v-model:items-per-page-options="options"
        :fixed-header="true"
        :headers="headers"
        :items="this.colliServer"
        v-model:items-per-page="itemsPerPage"
        v-model:sort-by="sortBy"
        :items-length="totalItems"
        @update:options="loadItems"
        style="
          width: 100%;
          border: 1px solid #d0d0d0;
          border-radius: 5px;
          max-height: 475px;
        "
      >
        <template #item="{ item }">
          <tr
              :class="{
              'custom-bg': (item.note != null && item.note != '' && (item.noteRistampa == null || item.noteRistampa == '')),
              'custom-bg-ristampa': (item.noteRistampa != null && item.noteRistampa != '')
            }"
          >
            <td>{{ item.ldv }}</td>
            <td>{{ item.stato }}</td>
            <td style="text-align: center">
              <v-chip v-if="item.bloccato" color="red" variant="flat">
                SI
              </v-chip>
              <v-chip v-else color="green" variant="flat"> NO</v-chip>
            </td>
            <td>{{item.discordanze}}</td>
            <td>{{item.lavorati}}</td>
            <td>
              <p v-if="item.data_creazione === null">NESSUNO</p>
              <p v-else>{{ this.formattaDataOra(item.data_creazione) }}</p>
            </td>
            <td>
              <p v-if="item.data_scansione === null">NESSUNO</p>
              <p v-else>{{ this.formattaDataOra(item.data_scansione) }}</p>
            </td>
            <td>
              <p v-if="item.emailAggiornatore === null">NESSUNO</p>
              <p v-else>{{ item.emailAggiornatore }}</p>
            </td>
            <td>
              <div
                  style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  column-gap: 10px;
                "
              >
                <v-icon
                    icon="mdi-information-outline"
                    @click="scansioniColli(item.ldv)"
                    color="blue"
                    size="x-large"
                ></v-icon>
                <v-icon
                    icon="mdi-file-edit"
                    @click="modificaCollo(item)"
                    color="black"
                    size="x-large"
                ></v-icon>
                <v-icon
                    icon="mdi-delete"
                    @click="eliminaCollo(item)"
                    color="red"
                    size="x-large"
                    style="background-color: rgb(255, 255, 255)"
                ></v-icon>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:header.discordanze="{ header }">
          <th>
            <v-tooltip location="top" text="DISCORDANZE">
              <template v-slot:activator="{ props }">
                <span v-bind="props">DIS.</span>
              </template>
            </v-tooltip>
          </th>
        </template>

        <template v-slot:header.lavorati="{ header }">
          <th>
            <v-tooltip location="top" text="LAVORATI">
              <template v-slot:activator="{ props }">
                <span v-bind="props">LAV.</span>
              </template>
            </v-tooltip>
          </th>
        </template>
      </v-data-table-server>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: start;
          column-gap: 10px;
          margin-bottom: 20px;
        "
      >
        <v-btn
          variant="outlined"
          height="48px"
          style="margin-top: 20px; background-color: #1d6f42; color: white"
          @click="exportExcel"
          >ESPORTA EXCEL</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
import Swal from "sweetalert2";
const axios = require("axios");
const { DateTime } = require("luxon");
export default {
  name: "ColliEntrataTable",

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  created() {
    this.dataScansioneDa = new Date();

    let date = new Date();
    date.setDate(date.getDate() + 7);
    this.dataScansioneA = date;
  },

  data() {
    return {
      searchingUtente: {
        text: "",
      },
      hasNote: false,
      panel: null,
      panel2: null,
      itemsPerPage: 10,
      totalItems: 0,
      currentPage: 0,
      headers: [
        { title: "LDV", value: "ldv", sortable: true },
        { title: "STATO COLLO", value: "stato", sortable: true },
        {
          title: "BLOCCO",
          value: "bloccato",
          sortable: false,
          align: "center",
        },
        {
          title: "DISCORDANZE",
          value: "discordanze",
          sortable: false,
          align: "center",
        },
        {
          title: "LAVORATI",
          value: "lavorati",
          sortable: false,
          align: "center",
        },
        { title: "DATA RICEZIONE", value: "data_creazione", sortable: true },
        {
          title: "DATA SCANSIONE",
          value: "data_scansione",
          sortable: true,
        },
        {
          title: "AUTORE AGGIORNAMENTO",
          value: "emailAggiornatore",
          sortable: false,
        },
        { title: "", value: "id", sortable: false },
      ],
      searchingCollo: {
        text: "",
      },
      colliServer: [],
      loading: true,
      stati: ["RICEVUTO_MAGAZZINO", "LAVORATO_ORDERS", "LAVORATO_RETURNS"],
      dataScansioneDa: null,
      dataScansioneA: null,
      filters: [],
      options: [10, 20, 30, 40, 50],
      sortBy: [{ key: "data_scansione", order: "desc" }],
    };
  },

  methods: {
    showScansionaForm() {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showScansionaColliEntrataForm");
    },
    modificaCollo(collo) {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("openModificaCollo", collo);
    },
    eliminaCollo(collo) {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("openEliminaCollo", collo);
    },
    async scansioniColli(ldv) {
      const apiUrl = `${this.$apiBaseUrl}/scansioni/${ldv}`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        let scansioni = response.data;
        console.log(scansioni);
        this.store.dispatch("changeBodyClass", "formOpen");
        this.store.dispatch("openScansioniCollo", [ldv, scansioni]);
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento delle scansioni del collo`,
          icon: "error",
        });
      }
    },
    searchCollo() {
      this.itemsPerPage = 10;
      this.currentPage = 1;
      this.loadItems({ page: 1, itemsPerPage: 10, sortBy: this.sortBy });
    },
    resetFiltri() {
      this.searchingCollo.text = "";
      this.searchingUtente.text = "";
      let date = new Date();
      date.setDate(date.getDate() + 7);
      this.dataScansioneA = date;
      this.dataScansioneDa = new Date();

      this.searchCollo();
    },
    async exportExcel() {
      const apiUrl = `${this.$apiBaseUrl}/export/`;
      const bearerToken = this.$keycloak.token;

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: "blob",
      };

      let urlParams = {
        dataScansioneDa:
          this.dataScansioneDa == null
            ? null
            : DateTime.fromJSDate(this.dataScansioneDa)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        dataScansioneA:
          this.dataScansioneA == null
            ? null
            : DateTime.fromJSDate(this.dataScansioneA)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        stati: this.stati,
        userMail:
          this.searchingUtente.text.trim().toLowerCase() == ""
            ? null
            : this.searchingUtente.text.trim().toLowerCase(),
      };

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log(response);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = "colli-entrata.csv";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento del file excel`,
          icon: "error",
        });
      }
    },
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date + "Z")
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
    async loadItems({ page, itemsPerPage, sortBy }) {
      this.panel = null;
      this.panel2 = null;
      this.loading = true;

      let filters = {
        dataScansioneDa:
          this.dataScansioneDa == null
            ? null
            : DateTime.fromJSDate(this.dataScansioneDa)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        dataScansioneA:
          this.dataScansioneA == null
            ? null
            : DateTime.fromJSDate(this.dataScansioneA)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        stati: this.stati,
        ldv: this.searchingCollo.text == "" ? null : this.searchingCollo.text,
        userMail:
          this.searchingUtente.text.trim().toLowerCase() == ""
            ? null
            : this.searchingUtente.text.trim().toLowerCase(),
        hasNote: this.hasNote,
      };
      console.log(sortBy, page, itemsPerPage, filters);
      const apiUrl = `${this.$apiBaseUrl}/colli/findAllColli`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      console.log(sortBy);

      let urlParams = {
        filtro: filters,
        sortBy: sortBy[0],
        page: page - 1,
        itemsPage: itemsPerPage,
      };

      console.log(urlParams);

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log("Response:", response.data);
        this.colliServer = response.data.colliViewList;
        this.totalItems = response.data.length;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei colli in entrata`,
          icon: "error",
        });
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
  width: fit-content;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-input {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}

.custom-bg {
  background-color: aqua;
}

.custom-bg-ristampa {
  background-color: #df3434;
}
</style>
