<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <div class="filters-actions-container">
        <div class="actions">
          <v-btn
            height="48"
            width="200"
            variant="outlined"
            @click="showAggiungiChiusura"
            >AGGIUNGI CHIUSURA</v-btn
          >
        </div>
        <div class="filters">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: end;
              row-gap: 5px;
            "
          >
            <v-dialog width="375px">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="outlined"
                  text="DATA CHIUSURA"
                  height="48px"
                  width="200"
                ></v-btn>
              </template>
              <template v-slot:default="{ isActive }">
                <v-card title="SELEZIONA DATA CHIUSURA">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-date-picker
                      style="border: 1px solid #d0d0d0 !important"
                      v-model="this.dataChiusura"
                    ></v-date-picker>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text="INVIA"
                      @click="isActive.value = false"
                      class="bg-green"
                      variant="outlined"
                    ></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <small>{{ this.formatDate(this.dataChiusura) }}</small>
          </div>
          <v-btn
            @click="searchChiusure"
            icon="mdi-magnify"
            class="bg-blue"
            variant="outlined"
          />
          <v-btn
            variant="outlined"
            height="48"
            class="bg-red"
            @click="resetFiltri"
            >RESET FILTRI</v-btn
          >
        </div>
      </div>
    </div>
    <div style="width: 95%">
      <v-data-table
        :loading="loading"
        v-model:items-per-page-options="options"
        :fixed-header="true"
        :headers="headers"
        :items="this.chiusureGiornaliere"
        style="
          width: 100%;
          border: 1px solid #d0d0d0;
          border-radius: 5px;
          max-height: 475px;
        "
      >
        <template v-slot:item.dataChiusura="{ item }">
          {{ this.formattaDataOra(item.dataChiusura) }}
        </template>
        <template v-slot:item.note="{ item }">
          <v-icon
            icon="mdi-information-outline"
            @click="showNoteChiusura(item.note)"
            color="blue"
            size="x-large"
          ></v-icon>
        </template>
      </v-data-table>
    </div>
    <v-dialog width="fit-content" v-model="this.dialogAggiungiChiusura">
      <v-card title="AGGIUNGI CHIUSURA">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 20px;
            margin: 20px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 20px;
            "
          >
            <v-text-field
              density="comfortable"
              variant="outlined"
              label="EMAIL"
              hide-details
              style="width: 245px !important; flex: none !important"
              v-model="this.emailOperatore.text"
            ></v-text-field>
            <v-text-field
              density="comfortable"
              variant="outlined"
              label="ADDETTO CHIUSURA PACCHI"
              hide-details
              style="width: 245px !important; flex: none !important"
              v-model="this.addettoChiusuraPacchi.text"
            ></v-text-field>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 20px;
            "
          >
            <v-number-input
              control-variant="split"
              variant="outlined"
              label="ORDINI LAVORATE"
              style="width: 245px !important; flex: none !important"
              v-model="this.lavoratiOrdini"
              hide-details
              min="0"
            ></v-number-input>
            <v-number-input
              control-variant="split"
              variant="outlined"
              label="RESI LAVORATE"
              style="width: 245px !important; flex: none !important"
              v-model="this.lavoratiResi"
              hide-details
              min="0"
            ></v-number-input>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 20px;
            "
          >
            <v-number-input
              control-variant="split"
              variant="outlined"
              label="INGRESSI LAVORATE"
              style="width: 245px !important; flex: none !important"
              v-model="this.lavoratiIngressi"
              hide-details
              min="0"
            ></v-number-input>
            <v-number-input
              control-variant="split"
              variant="outlined"
              label="ORE LAVORATE"
              style="width: 245px !important; flex: none !important"
              v-model="this.oreLavorate"
              hide-details
              min="0"
            ></v-number-input>
          </div>
          <v-textarea
            v-model="this.aggiungiNoteChiusura.text"
            label="NOTE"
            rows="10"
            width="500"
            style="width: 500px"
            no-resize
          ></v-textarea>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="outlined"
            width="100"
            height="48"
            text="CHIUDI"
            class="bg-red"
            @click="this.dialogAggiungiChiusura = false"
          ></v-btn>
          <v-btn
            variant="outlined"
            width="100"
            height="48"
            text="INVIA"
            class="bg-green"
            @click="aggiungiChiusura"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="fit-content" v-model="this.dialogNoteChiusura">
      <v-card title="NOTE CHIUSURA">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 20px;
            margin: 20px;
          "
        >
          <v-textarea
            :model-value="this.noteChiusura.text"
            label=""
            rows="10"
            width="500"
            style="width: 500px"
            no-resize
            :readonly="true"
          ></v-textarea>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="outlined"
            width="100"
            height="48"
            text="CHIUDI"
            @click="this.dialogNoteChiusura = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
const { DateTime } = require("luxon");
import Swal from "sweetalert2";
const axios = require("axios");
import { VNumberInput } from "vuetify/labs/VNumberInput";
export default {
  name: "ChiusureGiornaliereOperatoreTable",

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
    VNumberInput,
  },

  data() {
    return {
      dialogNoteChiusura: false,
      dialogAggiungiChiusura: false,
      headers: [
        { title: "EMAIL", value: "emailCreatore", sortable: true },
        { title: "ORDINI LAVORATI", value: "totaleOrdini", sortable: true },
        { title: "RESI LAVORATI", value: "totaleResi", sortable: true },
        {
          title: "INGRESSI LAVORATI",
          value: "totaleIngressi",
          sortable: true,
        },
        { title: "TEMPO LAVORATO", value: "tempoLavorato", sortable: true },
        {
          title: "ADDETTO CHIUSURA",
          value: "addettoChiusura",
          sortable: true,
        },
        {
          title: "DATA CHIUSURA",
          value: "dataChiusura",
          sortable: true,
        },
        {
          title: "",
          value: "note",
          sortable: false,
        },
      ],
      loading: true,
      dataChiusura: null,
      options: [10, 20, 30, 40, 50],
      chiusureGiornaliere: [],
      noteChiusura: {
        text: "",
      },
      aggiungiNoteChiusura: {
        text: "",
      },
      emailOperatore: {
        text: "",
      },
      addettoChiusuraPacchi: {
        text: "",
      },
      lavoratiOrdini: 0,
      lavoratiResi: 0,
      lavoratiIngressi: 0,
      oreLavorate: 0,
    };
  },

  mounted() {
    this.dataChiusura = new Date();
    this.emailOperatore.text = this.store.state.global.userData.email;
    this.searchChiusure();
  },

  methods: {
    resetFiltri() {
      this.dataChiusura = new Date();
      this.loading = true;
      this.searchChiusure();
    },
    showNoteChiusura(note) {
      this.dialogNoteChiusura = true;
      if (note === null) {
        this.noteChiusura.text = "NESSUNA NOTA";
      } else {
        this.noteChiusura.text = note;
      }
    },
    async showAggiungiChiusura() {
      const apiUrl = `${this.$apiBaseUrl}/colli/produzione/totale`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        this.lavoratiOrdini = response.data.ordini;
        this.lavoratiResi = response.data.resi;
        this.lavoratiIngressi = response.data.ingressi;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei colli lavorati dell'operatore`,
          icon: "error",
        });
      }
      this.dialogAggiungiChiusura = true;
    },
    async aggiungiChiusura() {
      // api post aggiungi chiusura
      const apiUrl = `${this.$apiBaseUrl}/chiusureGiornaliere/`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      let urlParams = {
        emailCreatore: this.emailOperatore.text,
        totaleOrdini: this.lavoratiOrdini,
        totaleResi: this.lavoratiResi,
        totaleIngressi: this.lavoratiIngressi,
        tempoLavorato: this.oreLavorate,
        addettoChiusura: this.addettoChiusuraPacchi.text,
        note: this.aggiungiNoteChiusura.text,
        dataChiusura: DateTime.now()
          .setZone("Europe/Rome")
          .toFormat("yyyy-MM-dd"),
      };

      console.log(urlParams);

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log("Response:", response.data);
        this.dialogAggiungiChiusura = false;
        this.loading = true;
        this.searchChiusure();
      } catch (error) {
        console.error("Error:", error.message);
        this.dialogAggiungiChiusura = false;
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nella creazione della chiusura giornaliera`,
          icon: "error",
        });
      }
    },
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromFormat(date, "yyyy-MM-dd")
        .toFormat("dd/MM/yyyy");
    },
    async searchChiusure() {
      this.loading = true;
      const apiUrl = `${this.$apiBaseUrl}/chiusureGiornaliere/chiusureGiornaliereOperatore`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      let urlParams = {
        dataChiusura: DateTime.fromJSDate(this.dataChiusura)
          .setZone("Europe/Rome")
          .toFormat("yyyy-MM-dd"),
      };

      console.log(urlParams);

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log("Response:", response.data);
        this.chiusureGiornaliere = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento delle chiusure giornaliere`,
          icon: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
  width: fit-content;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}
</style>
