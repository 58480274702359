<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      row-gap: 30px;
    "
  >
    <h1>NON HAI IL PERMESSO PER ENTRARE IN QUESTA PAGINA</h1>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
      "
    >
      <v-btn variant="outlined" to="/" height="48px">TORNA ALLA HOME</v-btn>
      <v-btn
        variant="outlined"
        class="bg-green"
        @click="login"
        height="48px"
        v-if="!this.store.state.global.authorized"
        >EFFETTUA IL LOGIN</v-btn
      >
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "ForbiddenView",

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  methods: {
    login() {
      if (this.$keycloak) {
        this.$keycloak.login();
      } else {
        console.error("Keycloak not initialized yet.");
        // Handle the situation accordingly, e.g., redirect to a login page.
      }
    },
  },
};
</script>

<style scoped></style>
