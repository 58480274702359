<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    "
  >
    <div style="width: 100%">
      <div class="page-title-container">
        <p class="title-container">CHIUSURE GIORNALIERE</p>
      </div>
      <div class="table-container">
        <ChiusureGiornaliereOperatoreTable />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import ChiusureGiornaliereOperatoreTable from "../components/ChiusureGiornaliereOperatoreTable.vue";
export default {
  name: "ChiusureGiornaliereOperatoreView",

  data() {
    return {};
  },

  methods: {},

  components: {
    ChiusureGiornaliereOperatoreTable,
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },
};
</script>

<style scoped>
.page-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  font-size: 2rem;
  letter-spacing: 2px;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

#form-position {
  position: absolute;
  z-index: 4 !important;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
</style>
