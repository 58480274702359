<template>
  <div>
    <v-card title="BLOCCA COLLO">
      <v-card-text>
        <v-container>
          <v-text-field
            style="width: 300px"
            label="CODICE COLLO"
            required
            variant="outlined"
            v-model="this.codiceCollo"
          ></v-text-field>
        </v-container>
        <small style="color: red">{{ this.errorMsg }}</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" variant="outlined" @click="closeForm">
          CHIUDI
        </v-btn>
        <v-btn
          @click="checkCodice"
          color="black"
          variant="outlined"
          class="bg-green"
          text="INVIA"
        >
        </v-btn>
        <v-dialog width="500" v-model="this.dialog">
          <v-card
            min-width="500px"
            title="ATTENZIONE"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: fit-content;
              row-gap: 50px;
              padding-top: 30px;
            "
          >
            <div class="warning-text-container">
              <p class="warning-text">
                SEI SICURO DI VOLER BLOCCARE IL COLLO:<br /><span
                  class="codice-collo"
                  >{{ this.codiceCollo }}</span
                >
              </p>
            </div>
            <v-card-actions style="column-gap: 50px">
              <v-btn
                text="SI"
                variant="outlined"
                class="bg-green"
                width="100"
                height="50"
                @click="bloccaCollo()"
              >
              </v-btn>
              <v-btn
                variant="outlined"
                class="bg-red"
                width="100"
                height="50"
                text="NO"
                @click="this.dialog = false"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "BloccaColloForm",

  data() {
    return {
      codiceCollo: "",
      errorMsg: "",
      dialog: false,
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  methods: {
    closeForm() {
      this.codiceCollo = "";
      this.errorMsg = "";
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeBloccaColloForm");
    },
    bloccaCollo() {
      if (this.codiceCollo !== "") {
        this.dialog = false;
        this.$emit("blocca-collo", this.codiceCollo);
        this.codiceCollo = "";
        this.errorMsg = "";
      } else {
        this.dialog = false;
        this.errorMsg = "Compila tutti i campi";
      }
    },
    checkCodice() {
      if (this.codiceCollo !== "") {
        this.dialog = true;
      } else {
        this.errorMsg = "Compila tutti i campi";
      }
    },
  },
};
</script>

<style scoped>
.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.warning-text {
  text-align: center;
  font-size: 1.2rem;
  width: 400px;
}

.codice-collo {
  font-weight: 600;
  font-size: 1.5rem;
}
</style>
