<!-- scansioni -->
<template>
    <div>
        <v-card width="1050">
            <v-card-title>SCANSIONI COLLO {{ this.ldv }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-data-table v-model:items-per-page-options="options" :fixed-header="true" :items="this.scansioni" :headers="this.headers" style="border: 1px solid #D0D0D0; border-radius: 5px; max-height: 475px;">
                        <template v-slot:item.emailCreatore="{ item }">
                            <p v-if="item.emailCreatore === null || item.emailCreatore === 'null'">NESSUNO</p>
                            <p v-else>{{ item.emailCreatore }}</p>
                        </template>
                        <template v-slot:item.dataCreazione="{ item }">
                            <p v-if="item.dataCreazione === null || item.dataCreazione === 'null'">NESSUNO</p>
                            <p v-else>{{ this.formattaDataOra(item.dataCreazione) }}</p>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="closeDetails"
                >
                    CHIUDI
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    const { DateTime } = require("luxon");
    export default {
        name: "ScansioniCollo",

        props: {
            scansioni: {
                type: Array
            },
            ldv: {
                type: String,
            }
        },

        data() {
            return {
                headers: [
                    { title: "STATO", value: 'stato', sortable: true, },
                    { title: "DATA CREAZIONE", value: 'dataCreazione', sortable: true, },
                    { title: "EMAIL CREATORE", value: 'emailCreatore', sortable: true, },
                ],
                options: [10, 20, 30, 40, 50]
            }
        },

        setup() {
            const store = useStore();
            return {
                store,
                dateTime: DateTime,
            };
        },

        methods: {
            closeDetails() {
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeScansioniCollo')
            },
            formattaDataOra(date) {
                return this.dateTime.fromISO(date + "Z").setZone('Europe/Rome').setLocale('it').toFormat('dd/MM/yyyy | HH:mm:ss')
            },
        },

        mounted() {
            console.log(this.scansioni);
        },
    }
</script>

<style scoped>

</style>