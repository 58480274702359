import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Keycloak from "keycloak-js";
import VueJwtDecode from "vue-jwt-decode";
import AddToHomescreen from "@owliehq/vue-addtohomescreen";
import "./registerServiceWorker";

loadFonts();
// createApp(App).use(router).use(store).use(vuetify).mount("#app");

function loadVueJs(keycloak, auth, configJson) {
  const app = createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(AddToHomescreen, {
      buttonColor: "blue",
    });
  app.config.globalProperties.$keycloak = keycloak;
  app.config.globalProperties.$apiBaseUrl = configJson["api"]["baseUrl"];
  app.mount("#app");

  if (auth) {
    console.log("Authenticated", auth);
    console.log(keycloak);
    localStorage.setItem("vue-token", keycloak.token);
    localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

    const token_decoded = VueJwtDecode.decode(keycloak.token);
    console.log(token_decoded);

    store.dispatch("changeAuthorized", true);
    store.dispatch("addUserData", token_decoded);

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 60000);
  } else {
    store.dispatch("changeAuthorized", false);
  }
}

function loadKeycloak(configJson) {
  console.log(configJson, "configJson");
  let initOptions = {
    ...configJson["sso"],
    onLoad: "check-sso",
  };

  console.log("KC config", initOptions);

  let keycloak = new Keycloak(initOptions);

  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => loadVueJs(keycloak, auth, configJson))
    .catch((e) => {
      console.error("Authenticated Failed", e);
    });
}

fetch("/config/config.json")
  .then((response) => response.json())
  .then((configJson) => loadKeycloak(configJson));
