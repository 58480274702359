<template>
  <div>
    <v-card title="SCANSIONA COLLI" width="400px">
      <div
        v-if="this.loading"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <Icon icon="line-md:loading-loop" style="color: black" width="200" />
      </div>
      <div
        v-else
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
        "
      >
        <v-date-input
          v-model="this.dataEntrata"
          label="Seleziona data entrata"
          width="300"
          :max="new Date()"
        ></v-date-input>
        <v-textarea
          v-model="this.store.state.colliUscitaEntrata.awbsEntrata"
          label="AWB"
          rows="10"
          no-resize
          style="width: 300px"
          hide-details
          variant="outlined"
        ></v-textarea>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text="CHIUDI"
          @click="closeScansionaColliForm"
          class="bg-red"
          variant="outlined"
        ></v-btn>
        <v-btn variant="outlined" class="bg-green" @click="inviaScansioni">
          INVIA SCANSIONI
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
const axios = require("axios");
import Swal from "sweetalert2";
const { DateTime } = require("luxon");
import { VDateInput } from "vuetify/labs/VDateInput";
export default {
  name: "ScansionaColliEntrataForm",

  data() {
    return {
      loading: false,
      dataEntrata: null,
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
    VDateInput,
  },

  mounted() {
    this.dataEntrata = new Date();
  },

  methods: {
    async inviaScansioni() {
      let awbsArray =
        this.store.state.colliUscitaEntrata.awbsEntrata.split("\n");
      let errors = [];
      // Elimino eventuali spazi
      awbsArray = awbsArray.map((item) => item.trim());
      awbsArray = awbsArray.filter((linea) => linea.trim() !== "");
      if (awbsArray.length > 0) {
        this.loading = true;
        for (var line of awbsArray) {
          console.log(line);
          let awb = line.split(" ")[0];
          let times = line.split(" ")[1];
          for (let index = 1; index <= times; index++) {
            let data = {
              ldv: awb,
              stato: "RICEVUTO_MAGAZZINO",
              createDate: this.dataEntrata,
            };

            const apiUrl = `${this.$apiBaseUrl}/colli`;
            const bearerToken = this.$keycloak.token;
            console.log(bearerToken);

            const config = {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
              },
            };

            try {
              const response = await axios.post(apiUrl, data, config);
              console.log("Response:", response.data);
            } catch (error) {
              console.error("Error:", error.message);
              errors.push(awb);
            }
          }
        }
        this.store.dispatch("changeAwbsEntrata", "");
        if (errors.length > 0) {
          Swal.fire({
            title: "ERRORE",
            text: `C'è stato un errore nell'invio delle scansioni: ${errors}`,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "SCANSIONI RIUSCITE",
            text: `Le scansioni sono state inviate.`,
            icon: "success",
          });
        }
        this.loading = false;
      }
    },
    closeScansionaColliForm() {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("closeScansionaColliEntrataForm");
    },
  },
};
</script>

<style scoped></style>
