<template>
  <div>
    <v-card
      min-width="500px"
      title="ATTENZIONE"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        row-gap: 50px;
        padding-top: 30px;
      "
    >
      <div class="warning-text-container">
        <p class="warning-text">
          SEI SICURO DI VOLER SBLOCCARE IL COLLO:<br /><span
            class="codice-collo"
            >{{ this.collo.ldv }}</span
          >
        </p>
      </div>
      <v-card-actions style="column-gap: 50px">
        <v-btn
          text="SI"
          variant="outlined"
          class="bg-green"
          width="100"
          height="50"
          @click="sbloccaCollo"
        >
        </v-btn>
        <v-btn
          variant="outlined"
          class="bg-red"
          width="100"
          height="50"
          text="NO"
          @click="closeSbloccaColloConfirm"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "SbloccaColloConfirm",

  data() {
    return {};
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  props: {
    collo: {
      type: Object,
    },
  },

  methods: {
    sbloccaCollo() {
      this.$emit("sblocca-collo", this.collo.id, this.collo.ldv);
    },
    closeSbloccaColloConfirm() {
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeSbloccaColloConfirm");
    },
  },
};
</script>

<style scoped>
.warning-text {
  text-align: center;
  font-size: 1.2rem;
  width: 400px;
}

.codice-collo {
  font-weight: 600;
  font-size: 1.5rem;
}
</style>
