<template>
  <div
    style="
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: column;
      height: calc(100vh - 70px);
    "
  >
    <div
      style="
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
        width: 300px;
      "
    >
      <h2>SCANSIONE COLLI</h2>
    </div>
    <div
      style="
        display: flex;
        align-items: left;
        justify-content: start;
        flex-direction: column;
        width: 300px;
      "
      v-if="!this.isOnline"
    >
      <p>Modalità Offline</p>
      <p>
        {{ this.numScansioniOffline }} scansione/i non ancora sincronizzata/e
      </p>
    </div>
    <div
      style="
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-bottom: 20px;
      "
    >
      <h2>CODICE COLLO</h2>
      <form
        @submit.prevent="inviaScansione"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
        "
      >
        <v-text-field
          style="width: 300px; margin-top: 10px"
          label="Codice collo"
          required
          hide-details
          variant="outlined"
          v-model="this.codiceCollo"
          ref="alwaysFocus"
        ></v-text-field>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            row-gap: 5px;
          "
        >
          <v-dialog persistent width="355px">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="outlined"
                text="DATA RICEZIONE"
                height="56px"
                width="300"
              ></v-btn>
            </template>
            <template v-slot:default="{ isActive }">
              <v-card>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-date-picker
                    style="border: 1px solid #d0d0d0 !important"
                    v-model="this.dataRicezione"
                  ></v-date-picker>
                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text="INVIA"
                    @click="
                      () => {
                        isActive.value = false;
                        setDate();
                      }
                    "
                    class="bg-green"
                    variant="outlined"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <small>Data selezionta: {{ formattedDataRicezione }}</small>
        </div>
        <small style="color: red">{{ codiceColloError }}</small>
        <h2>
          N° colli lavorati: <span>{{ this.lavorati }}</span>
        </h2>
        <v-btn
          variant="outlined"
          height="56px"
          width="300px"
          style="margin-top: 20px"
          type="submit"
        >
          INVIA SCANSIONE
        </v-btn>
        <h2>{{ this.prevCodiceCollo }}</h2>
      </form>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
const axios = require("axios");
import Swal from "sweetalert2";
export default {
  name: "MobileScanView",
  data() {
    return {
      numScansioniOffline: 0,
      codiceCollo: "",
      prevCodiceCollo: "",
      dataRicezione: null,
      formattedDataRicezione: null,
      codiceColloError: "",
      isOnline: navigator.onLine,
      lavorati: 0,
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  created() {
    this.createDb();
    let date = new Date();
    this.dataRicezione = date;
    console.log(this.dataRicezione);
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },

  mounted() {
    this.$refs.alwaysFocus.focus();
    this.updateOnlineStatus();
    this.fetchLavorati();
  },

  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },

  methods: {
    async fetchLavorati() {
      const apiUrl = `${this.$apiBaseUrl}/colli/produzione/totale`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        this.lavorati = response.data.ingressi;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei colli in entrata`,
          icon: "error",
        });
      }
    },
    countScans() {
      // salva in un array i dati e poi li invia al server
      let numScan = 0;
      const self = this;
      // Apro la transazione e il cursore sull'object store
      try {
        // Apro la connessione al database
        const request = indexedDB.open("scansioniFallite");

        request.onerror = function (event) {
          console.log("Errore nell'apertura del database");
        };

        request.onsuccess = function (event) {
          const db = event.target.result;

          const transaction = db.transaction(["scansioni"], "readonly");
          console.log(transaction);
          const objectStore = transaction.objectStore("scansioni");
          console.log(objectStore);
          const cursorRequest = objectStore.openCursor();

          cursorRequest.onerror = function (event) {
            console.error(
              "Errore durante l'iterazione del cursore:",
              event.target.error
            );
          };

          cursorRequest.onsuccess = function (event) {
            var cursor = event.target.result;
            console.log(cursor);
            if (cursor) {
              console.log(cursor.value);
              numScan += 1;
              // Passo al prossimo elemento
              cursor.continue();
            } else {
              // Il cursore ha terminato l'iterazione sugli elementi
              console.log(numScan, "fine");
              self.numScansioniOffline = numScan;
              console.log("Fine dell'object store");
            }
          };
          transaction.oncomplete = function (event) {
            // Chiusura del database
            db.close();
          };
        };
      } catch (error) {
        console.log("error");
      }
    },
    async inviaScansione() {
      this.$refs.alwaysFocus.focus();
      if (this.codiceCollo && this.codiceCollo != "") {
        let date = new Date();
        let newCollo = {
          ldv: this.codiceCollo.trim(),
          stato: "RICEVUTO_MAGAZZINO",
          createDate:
            this.dataRicezione.getFullYear() !== date.getFullYear() ||
            this.dataRicezione.getMonth() !== date.getMonth() ||
            this.dataRicezione.getDate() !== date.getDate()
              ? this.dataRicezione
              : date,
        };
        this.codiceColloError = "";
        const apiUrl = `${this.$apiBaseUrl}/colli`;
        const bearerToken = this.$keycloak.token;
        console.log(bearerToken);
        const config = {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        };
        try {
          const response = await axios.post(apiUrl, newCollo, config);
          console.log("Response:", response.data);
          if (!this.codiceCollo.trim().match(/^[0-9]+$/g)) {
            playSound("bump", { volume: 10 });
          } else {
            playSound("coin", { volume: 10 });
          }
          this.fetchLavorati();
        } catch (error) {
          playSound("smash", { volume: 10 });
          console.error("Error:", error.message);
          const cod = this.codiceCollo;
          const ricezione =
            this.dataRicezione.getFullYear() !== date.getFullYear() ||
            this.dataRicezione.getMonth() !== date.getMonth() ||
            this.dataRicezione.getDate() !== date.getDate()
              ? this.dataRicezione
              : date;
          const dbName = "scansioniFallite";

          const request = indexedDB.open(dbName);
          console.log(request);
          request.onerror = (event) => {
            // Gestisci gli errori.
            console.log("Errore durante l'apertura del database");
          };
          request.onsuccess = (event) => {
            const db = event.target.result;
            console.log(db);
            // Dopo che l'object store è stato creato o verificato nell'evento onupgradeneeded,
            // possiamo aggiungere direttamente l'oggetto.
            const transaction = db.transaction("scansioni", "readwrite");
            const scanzioniObjectStore = transaction.objectStore("scansioni");
            console.log(scanzioniObjectStore);
            let now = new Date(); // Crea un nuovo oggetto Date con l'istante corrente
            let timestamp = now.getTime();
            let scansione = {
              id: timestamp,
              ldv: cod,
              data_ricezione: ricezione,
              stato: "RICEVUTO_MAGAZZINO",
            };
            scanzioniObjectStore.add(scansione);
            this.countScans();
          };
        }
      } else {
        this.codiceColloError = "Inserisci codice collo";
      }
      this.$refs.alwaysFocus.focus();
      this.prevCodiceCollo = this.codiceCollo;
      this.codiceCollo = "";
    },
    createDb() {
      const dbName = "scansioniFallite";

      const request = indexedDB.open(dbName);
      console.log(request);
      request.onerror = (event) => {
        // Gestisci gli errori.
        console.log("Errore durante l'apertura del database");
      };
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        console.log(db);
        console.log(db.objectStoreNames);
        if (!db.objectStoreNames.contains("scansioni")) {
          // L'object store 'scansioni' non esiste, lo creiamo.
          const objectStore = db.createObjectStore("scansioni", {
            keyPath: "id",
          });
          console.log(objectStore);
          objectStore.createIndex("ldv", "ldv", { unique: false });
          objectStore.createIndex("data_ricezione", "data_ricezione", {
            unique: false,
          });
          objectStore.createIndex("stato", "stato", { unique: false });
        }
      };
    },
    formatDate(inputDate) {
      // Parse the input date string
      const date = new Date(inputDate);

      // Get day, month, and year components
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();

      // Construct the formatted date string
      const formattedDate = `${day} / ${month} / ${year}`;

      return formattedDate;
    },
    loadDataFromDb() {
      // salva in un array i dati e poi li invia al server
      const self = this;
      let scansToRemove = [];
      // Apro la transazione e il cursore sull'object store
      try {
        // Apro la connessione al database
        const request = indexedDB.open("scansioniFallite");

        request.onerror = function (event) {
          console.log("Errore nell'apertura del database");
        };

        request.onsuccess = function (event) {
          const db = event.target.result;

          const transaction = db.transaction(["scansioni"], "readonly");
          console.log(transaction);
          const objectStore = transaction.objectStore("scansioni");
          console.log(objectStore);
          const cursorRequest = objectStore.openCursor();

          cursorRequest.onerror = function (event) {
            console.error(
              "Errore durante l'iterazione del cursore:",
              event.target.error
            );
          };

          cursorRequest.onsuccess = function (event) {
            var cursor = event.target.result;
            console.log(cursor);
            if (cursor) {
              console.log(cursor.value);
              self
                .doScan(cursor.value)
                .then(() => {
                  scansToRemove.push(cursor.value.id);
                  cursor.continue();
                })
                .catch((error) => {
                  console.error(
                    "Errore durante l'esecuzione di doScan:",
                    error
                  );
                });
            } else {
              // Il cursore ha terminato l'iterazione sugli elementi
              console.log(scansToRemove);
              console.log(scansToRemove.length);
              if (scansToRemove.length > 0) {
                scansToRemove.forEach((scan) => {
                  self
                    .removeScan("scansioniFallite", "scansioni", scan)
                    .then(() => {
                      console.log("rimosso");
                    })
                    .catch((error) => {
                      console.error(
                        "Errore durante l'esecuzione di removeScan:",
                        error
                      );
                    });
                });
                Swal.fire({
                  text: `SONO STATE ESEGUITE LE SCANSIONI FALLITE PRECEDENTEMENTE`,
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  timerProgressBar: true,
                });
              }
              console.log("Fine dell'object store");
            }
          };
          transaction.oncomplete = function (event) {
            // Chiusura del database
            db.close();
          };
        };
      } catch (error) {
        console.log("error");
      }
    },
    doScan(scan) {
      return new Promise((resolve, reject) => {
        console.log(scan);
        let newScan = {
          ldv: scan.ldv.trim(),
          stato: scan.stato,
          createDate: scan.data_ricezione,
        };
        const apiUrl = `${this.$apiBaseUrl}/colli`;
        const bearerToken = this.$keycloak.token;
        const config = {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        };
        axios
          .post(apiUrl, newScan, config)
          .then((response) => {
            console.log("Response:", response.data);
            resolve(); // Risolvi la promessa dopo il successo
          })
          .catch((error) => {
            reject("Errore nell'eseguzione della scan fallita: " + error);
          });
        resolve();
      });
    },
    removeScan(dbName, objectStoreName, id) {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);

        request.onerror = (event) => {
          reject("Errore nell'apertura del database: " + event.target.error);
        };

        request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction(objectStoreName, "readwrite");
          const objectStore = transaction.objectStore(objectStoreName);

          const deleteRequest = objectStore.delete(id);

          deleteRequest.onerror = (event) => {
            reject(
              "Errore durante l'eliminazione dell'oggetto: " +
                event.target.error
            );
          };

          deleteRequest.onsuccess = (event) => {
            resolve("Oggetto eliminato con successo.");
          };

          transaction.oncomplete = (event) => {
            db.close();
          };
        };
      });
    },
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
      if (this.isOnline === false) {
        this.countScans();
      } else {
        this.loadDataFromDb();
      }
    },
    setDate() {
      console.log(this.dataRicezione);
      this.dataRicezione = new Date(
        this.dataRicezione.setHours(23, 59, 59, 999)
      );
      console.log(this.dataRicezione);
    },
  },

  watch: {
    dataRicezione: function (newVal) {
      this.formattedDataRicezione = this.formatDate(this.dataRicezione);
    },
  },
};
</script>

<style scoped>
.swal2-timer-progress-bar {
  background-color: blue !important;
}
</style>
