<template>
    <div>
        <v-card min-width="400px" v-if="this.utente">
            <v-card-title>RESET PASSWORD DI {{this.utente.email}}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-text-field
                        label="PASSWORD"
                        type="password"
                        required
                        variant="outlined"
                        v-model="this.password"
                    ></v-text-field>
                    <v-text-field
                        label="REINSERISCI PASSWORD"
                        type="password"
                        required
                        variant="outlined"
                        v-model="this.password2"
                    ></v-text-field>
                </v-container>
                <small style="color: red">{{ this.errorMsg }}</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="closeForm"
                >
                    CHIUDI
                </v-btn>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="resetPwd"
                    class="bg-green"
                >
                    INVIA
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    export default {
        name: "ResetPwdForm",

        data() {
            return {
                password: "",
                password2: "",
                errorMsg: "",
            }
        },

        props: {
            utente: {
                type: Object
            }
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        methods: {
            closeForm() {
                this.errorMsg = ''
                this.password = ''
                this.password2 = ''
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeResetPwd')
            },
            resetPwd() {
                if((this.password !== '' && this.password2 !== '') && (this.password === this.password2)){
                    let newCredentials = {
                        userId: this.utente.id,
                        password: this.password
                    }
                    this.$emit('reset-pwd-utente', newCredentials, this.utente.email)
                    this.password = ''
                    this.password2 = ''
                    this.errorMsg = ''
                } else if(this.password !== this.password2) {
                    this.errorMsg = 'Le password non corrispondono'
                } else {
                    this.errorMsg = 'Compila tutti i campi'
                }
            }
        },

    }
</script>

<style scoped>
    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
    
</style>