<template>
  <div :class="'desktop sidebar ' + active" v-click-outside="onClickOutside">
    <div class="logo_content">
      <div class="logo">
        <img
          :src="TmlLogo"
          style="width: 110px; height: 50px; background-color: white"
        />
      </div>
      <i
        class="bx bx-menu"
        id="btn"
        @click="toggleActive"
        style="cursor: pointer"
      ></i>
    </div>
    <ul class="nav_list">
      <div class="link_icon_container" v-tooltip="'HOME'">
        <v-btn
          variant="outlined"
          to="/"
          style="transition: none; height: 50px; color: white"
          :ripple="false"
        >
          <Icon
            icon="mdi:home"
            class="link_icon"
            width="24"
            height="24"
            color="white"
          />
          <span class="links_name">HOME</span>
        </v-btn>
      </div>
      <div
        class="link_icon_container"
        v-tooltip="'AMMINISTRAZIONE'"
        v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
      >
        <v-menu
          location="end"
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="outlined"
              style="transition: none; height: 50px; color: white"
              v-bind="props"
            >
              <Icon
                icon="mdi:administrator"
                class="link_icon"
                width="24"
                height="24"
                color="white"
              />
              <span class="links_name">AMMINISTRAZIONE</span>
            </v-btn>
          </template>

          <v-list class="bg-white">
            <v-list-item
              variant="plain"
              v-for="item in linkAmministrazione"
              :key="item.name"
              :to="item.link"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
        class="link_icon_container"
        v-tooltip="'MIE CHIUSURE'"
        v-if="this.store.state.global.userData.roles.includes('ROLE_OPERATORE')"
      >
        <v-btn
          variant="outlined"
          to="/mie-chiusure"
          v-if="
            this.store.state.global.userData.roles.includes('ROLE_OPERATORE')
          "
          style="transition: none; height: 50px; color: white"
          ><Icon
            icon="mdi:file-excel"
            class="link_icon"
            width="24"
            height="24"
            color="white"
          />
          <span class="links_name">MIE CHIUSURE</span></v-btn
        >
      </div>

      <div
        class="link_icon_container"
        v-tooltip="'GESTIONE ORDINI'"
        v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
      >
        <v-btn
          variant="outlined"
          to="/ordini"
          style="transition: none; height: 50px; color: white"
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <Icon
            icon="mdi:package"
            class="link_icon"
            width="24"
            height="24"
            color="white"
          />
          <span class="links_name">GESTIONE ORDINI</span></v-btn
        >
      </div>

      <div
        class="link_icon_container"
        v-tooltip="'GESTIONE RESI'"
        v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
      >
        <v-btn
          variant="outlined"
          to="/resi"
          style="transition: none; height: 50px; color: white"
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <Icon
            icon="hugeicons:delivery-return-01"
            class="link_icon"
            width="24"
            height="24"
            color="white"
          />
          <span class="links_name">GESTIONE RESI</span></v-btn
        >
      </div>
      <div
        class="link_icon_container"
        v-tooltip="'SCANSIONA COLLI'"
        v-if="
          this.store.state.global.userData.roles.includes('ROLE_ADMIN') ||
          this.store.state.global.userData.roles.includes('ROLE_OPERATORE')
        "
      >
        <v-btn
          variant="outlined"
          to="/scansiona-colli"
          v-if="
            this.store.state.global.userData.roles.includes('ROLE_ADMIN') ||
            this.store.state.global.userData.roles.includes('ROLE_OPERATORE')
          "
          style="transition: none; height: 50px; color: white"
        >
          <Icon
            icon="mdi:barcode-scan"
            class="link_icon"
            width="24"
            height="24"
            color="white"
          /><span class="links_name">SCANSIONA COLLI</span></v-btn
        >
      </div>
      <div
        class="link_icon_container"
        v-tooltip="'GESTIONE COLLI'"
        v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
      >
        <v-menu
          location="end"
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="outlined"
              style="transition: none; height: 50px; color: white"
              v-bind="props"
            >
              <Icon
                icon="carbon:delivery-parcel"
                class="link_icon"
                width="24"
                height="24"
                color="white"
              />
              <span class="links_name">GESTIONE COLLI</span>
            </v-btn>
          </template>

          <v-list class="bg-white">
            <v-list-item
              v-for="item in linkColli"
              :key="item.name"
              :to="item.link"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </ul>
    <div class="profile_content">
      <div class="profile">
        <div class="profile_details">
          <div class="name_job">
            <div class="name">{{ this.store.state.global.userData.email }}</div>
            <div class="job">
              {{ this.store.state.global.userData.roles[0] }}
            </div>
          </div>
        </div>
        <i
          class="bx bx-log-out bg-red"
          id="log_out"
          @click="logout"
          style="cursor: pointer"
          v-tooltip="'LOGOUT'"
          v-if="this.store.state.global.authorized"
        ></i>
        <div
          class="link_icon_container bg-green"
          @click="login"
          v-tooltip="'LOGIN'"
          v-else
        >
          <Icon
            icon="ri:login-box-line"
            style="cursor: pointer"
            width="24"
            height="24"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-app-bar-nav-icon
          v-bind="props"
          style="border: 1px solid black"
        ></v-app-bar-nav-icon>
      </template>
      <v-list style="display: flex; flex-direction: column; row-gap: 10px">
        <v-btn variant="plain" to="/">HOME</v-btn>
        <v-menu
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <template v-slot:activator="{ props }">
            <v-btn variant="plain" v-bind="props">
              AMMINISTRAZIONE
              <v-icon end> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <v-list class="bg-white">
            <v-list-item
              variant="plain"
              v-for="item in linkAmministrazione"
              :key="item.name"
              :to="item.link"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          variant="plain"
          to="/mie-chiusure"
          v-if="
            this.store.state.global.userData.roles.includes('ROLE_OPERATORE')
          "
          >MIE CHIUSURE</v-btn
        >
        <v-btn
          variant="plain"
          to="/ordini"
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
          >GESTIONE ORDINI</v-btn
        >
        <v-btn
          variant="plain"
          to="/scansiona-colli"
          v-if="
            this.store.state.global.userData.roles.includes('ROLE_ADMIN') ||
            this.store.state.global.userData.roles.includes('ROLE_OPERATORE')
          "
          >SCANSIONA COLLI</v-btn
        >
        <v-menu
          v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"
        >
          <template v-slot:activator="{ props }">
            <v-btn variant="plain" v-bind="props">
              GESTIONE COLLI
              <v-icon end> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <v-list class="bg-white">
            <v-list-item
              variant="plain"
              v-for="item in linkColli"
              :key="item.name"
              :to="item.link"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="bg-red"
          variant="plain"
          @click="logout"
          v-if="this.store.state.global.authorized"
          >LOGOUT</v-btn
        >
        <v-btn variant="plain" class="bg-green" @click="login" v-else
          >LOGIN</v-btn
        >
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
import TmlLogo from "@/assets/tml-logo.png";
export default {
  name: "Header",
  components: {
    Icon,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  data() {
    return {
      TmlLogo,
      active: "",
      linkColli: [
        {
          name: "COLLI BLOCCATI",
          link: "colli-bloccati",
          icon: "",
        },
        {
          name: "COLLI IN ENTRATA",
          link: "colli-in-entrata",
          icon: "",
        },
        {
          name: "COLLI IN USCITA",
          link: "colli-in-uscita",
          icon: "",
        },
        {
          name: "BANCALI IN USCITA",
          link: "bancali-in-uscita",
          icon: "",
        },
      ],
      linkAmministrazione: [
        {
          name: "GESTIONI UTENTI",
          link: "utenti",
        },
        {
          name: "STAMPANTI",
          link: "stampanti",
        },
        {
          name: "CHIUSURE GIORNALIERE",
          link: "chiusure-giornaliere",
        },
      ],
    };
  },
  methods: {
    toggleActive() {
      this.active = this.active == "" ? "active" : "";
    },
    onClickOutside() {
      this.active = "";
    },
    toPage(page) {
      window.location.href = page;
    },
    logout() {
      console.log("Attempting to logout. this.$keycloak:", this.$keycloak);
      if (this.$keycloak) {
        this.$keycloak.logout();
      } else {
        console.error("Keycloak not initialized yet.");
        // Handle the situation accordingly, e.g., redirect to a login page.
      }
    },
    login() {
      if (this.$keycloak) {
        this.$keycloak.login();
      } else {
        console.error("Keycloak not initialized yet.");
        // Handle the situation accordingly, e.g., redirect to a login page.
      }
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  background-color: #2196f3;
  color: white;
}

.v-btn--active {
  background-color: #2196f3;
  color: white;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 78px;
  background: #1e1d33;
  padding: 6px 14px;
  z-index: 4;
}

.sidebar.active {
  width: 275px;
}

.nav_list {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 10px;
}

.link_icon_container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.logo {
  gap: 15px;
}

.sidebar .logo_content .logo {
  color: #fff;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.sidebar.active .logo_content .logo {
  opacity: 1;
}

.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}

.sidebar #btn {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 6px;
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar ul {
  margin-top: 20px;
}

.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 0 5px;
  list-style: none;
  line-height: 50px;
}

.sidebar ul li .tooltip {
  position: absolute;
  left: 122px;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  width: 122px;
  background: #fff;
  line-height: 35px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  display: block;
}

.sidebar.active ul li .tooltip {
  display: none;
}

.sidebar ul li:hover .tooltip {
  opacity: 1;
  top: 50%;
}

.sidebar ul li input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
  outline: none;
  border: none;
  background: #1d1b31;
  padding-left: 50px;
  font-size: 18px;
  color: #fff;
}

.sidebar ul li .bx-search {
  position: absolute;
  z-index: 99;
  color: #fff;
  font-size: 22px;
}

.sidebar ul li .bx-search:hover {
  background: #fff;
  color: #1d1b31;
}

.sidebar ul li a {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 12px;
  white-space: nowrap;
}

.sidebar ul li a:hover {
  color: #11101d;
  background: #fff;
}

.sidebar ul li i {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  line-height: 50px;
  text-align: center;
}

.sidebar .links_name {
  opacity: 0;
  display: none;
  pointer-events: none;
}

.sidebar.active .links_name {
  opacity: 1;
  width: 200px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.sidebar .profile_content {
  position: absolute;
  color: #fff;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.sidebar .profile_content .profile {
  position: relative;
  padding: 10px 6px;
  height: 60px;
  background: none;
}

.sidebar.active .profile_content .profile {
  background: #1d1b31;
}

.profile_content .profile .profile_details {
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
}

.sidebar.active .profile .profile_details {
  opacity: 1;
  pointer-events: auto;
}

.profile .profile_details .name_job {
  margin-left: 10px;
}

.profile .profile_details .name {
  font-size: 15px;
  font-weight: 400;
}

.profile .profile_details .job {
  font-size: 12px;
}

.profile #log_out {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  min-width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 12px;
  text-align: center;
  background: #1d1b31;
}

.sidebar.active .profile #log_out {
  left: 88%;
}

.sidebar.active .profile #log_out {
  background: none;
}

.mobile {
  display: none;
}

@media screen and (max-width: 420px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    padding-right: 12px;
  }
}
</style>
