<template>
   <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 50px">
        <div style="width: 100%;">
            <div class="page-title-container">
                <p class="title-container">
                    GESTIONE ORDINI
                </p>
            </div>
            <div class="table-container">
                <OrdiniTable />
            </div>
        </div>
        <div id="form-position" class="form-container" v-if="this.store.state.ordini.showContenutoOrdineState">
            <ContenutoOrdine :numOrdine="this.store.state.ordini.numOrdineSelezionato" :contenutoOrdine="this.store.state.ordini.contenutoOrdineSelezionato"/>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    const axios = require('axios');
    import OrdiniTable from "../components/OrdiniTable.vue";
    import ContenutoOrdine from "../components/ContenutoOrdine.vue";
    export default {
        name: "OrdiniView",

        data() {
            return {
            }
        },

        components: {
            OrdiniTable,
            ContenutoOrdine,
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        async created() {

        },

        methods: {
           
        },
    }
</script>

<style scoped>

.page-title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-container {
        font-size: 2rem;
        letter-spacing: 2px;
    }

    .table-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-container {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        background-color: rgba(0,0,0,0.25);
    }

    #form-position {
        position: absolute;
        z-index: 4 !important;
    }
    
    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

</style>