<template>
    <div style="margin-top: 50px">
        <div class="page-title-container">
            <p class="title-container">
                GESTIONE UTENTI
            </p>
        </div>
        <div class="table-container">
            <UtentiTable :utenti="utenti"/>
        </div>
        <div id="form-position" class="form-container" v-if="this.store.state.utenti.showEditUtenteFormState || this.store.state.utenti.showCreateUtenteFormState || this.store.state.utenti.showResetPwdState || this.store.state.utenti.showChangeAttivoState">
            <EditUtenteForm  @edit-utente="editUtente" :utente="this.store.state.utenti.editingUtente" v-if="this.store.state.utenti.showEditUtenteFormState"/>
            <CreateUtenteForm @create-utente="createUtente" v-if="this.store.state.utenti.showCreateUtenteFormState"/>
            <ResetPwdForm @reset-pwd-utente="resetPwdUtente" :utente="this.store.state.utenti.editingUtente" v-if="this.store.state.utenti.showResetPwdState"/>
            <ChangeAttivo @change-attivo="changeAttivo" :utente="this.store.state.utenti.editingUtente" :attivo="this.store.state.utenti.utenteAttivoState" v-if="this.store.state.utenti.showChangeAttivoState"/>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    import EditUtenteForm from "../components/EditUtenteForm.vue";
    import CreateUtenteForm from "../components/CreateUtenteForm.vue";
    import ResetPwdForm from '../components/ResetPwdForm.vue';
    import ChangeAttivo from '../components/ChangeAttivo.vue';
    import UtentiTable from '../components/UtentiTable.vue';
    import Swal from "sweetalert2";
    const axios = require('axios');
    export default {
        name: "UtentiView",
        
        components: {
            UtentiTable,
            EditUtenteForm,
            CreateUtenteForm,
            ResetPwdForm,
            ChangeAttivo,
        },

        data() {
            return {
                utenti: [],
            }
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        async created() {
            try {
                this.utenti = await this.fetchUsers();
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        },

        methods: {
            async fetchUsers() {
                const apiUrl = `${this.$apiBaseUrl}/utente`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nel caricamento degli utenti`,
                        icon: "error",
                    });
                }
            },

            async changeAttivo(utente, attivo, ruolo) {
                if(attivo == "no") {
                    const apiUrl = `${this.$apiBaseUrl}/utente/${utente.id}`;
                    const bearerToken = this.$keycloak.token;
                    console.log(bearerToken);

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };
                    try {
                        const response = await axios.delete(apiUrl, config);
                        console.log('Response:', response.data);
                        this.utenti = await this.fetchUsers();
                        Swal.fire({
                            title: "UTENTE DISATTIVATO",
                            text: `L'utente ${utente.email} è stato disattivato.`,
                            icon: "success",
                        });
                    } catch (error) {
                        console.error('Error:', error.message);
                        Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nel disattivare l'utente`,
                        icon: "error",
                    });
                    }
                } else {
                    let newUser = {
                        email: utente.email,
                        firstName: utente.firstName,
                        lastName: utente.lastName,
                        ruolo: ruolo,
                    }
                    const apiUrl = `${this.$apiBaseUrl}/utente`;
                    const bearerToken = this.$keycloak.token;
                    console.log(bearerToken);
                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };
                    try {
                        const response = await axios.put(apiUrl, newUser, config);
                        Swal.fire({
                            title: "UTENTE ATTIVATO",
                            text: `L'utente ${utente.email} è stato attivato.`,
                            icon: "success",
                        });
                    } catch (error) {
                        console.error('Error:', error.message);
                        Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nell'attivare l'utente`,
                        icon: "error",
                    });
                    }
                }
                
                this.store.dispatch("changeBodyClass", "")
                this.store.dispatch('closeChangeAttivo')
                this.utenti = await this.fetchUsers();
            },
            async editUtente(newUtente) {
                console.log(newUtente, "newUtente");
                const apiUrl = `${this.$apiBaseUrl}/utente`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);
                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };
                try {
                    const response = await axios.put(apiUrl, newUtente, config);
                    console.log(newUtente, "newUtente");
                    this.utenti = await this.fetchUsers();
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeEditUtenteForm')
                    Swal.fire({
                        title: "UTENTE MODIFICATO",
                        text: `L'utente ${newUtente.email} è stato modificato.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.error('Error:', error.message);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nella modifica dell'utente`,
                        icon: "error",
                    });
                }
            },
            async createUtente(newUtente) {
                console.log(newUtente, "newUtente");
                const apiUrl = `${this.$apiBaseUrl}/utente`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);
                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };
                try {
                    const response = await axios.post(apiUrl, newUtente, config);
                    console.log('Response:', response.data);
                    this.utenti = await this.fetchUsers();
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeCreateUtenteForm')
                    Swal.fire({
                        title: "UTENTE CREATO",
                        text: `L'utente ${newUtente.email} è stato creato.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.error('Error:', error.message);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nella creazione dell'utente`,
                        icon: "error",
                    });
                }
            },
            async resetPwdUtente(credentials, email) {
                const apiUrl = `${this.$apiBaseUrl}/utente/resetPassword`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);
                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };
                try {
                    console.log(credentials.password, "password");
                    console.log(credentials.userId, "userId");
                    const response = await axios.put(apiUrl, credentials, config);
                    this.utenti = await this.fetchUsers()
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeResetPwd')
                    Swal.fire({
                        title: "PASSWORD MODIFICATA",
                        text: `La password dell'utente ${email} è stata modificata.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.error('Error:', error.message);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nel reset della password`,
                        icon: "error",
                    });
                }
               
            },
        },
    }
</script>

<style scoped>

    .page-title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-container {
        font-size: 2rem;
        letter-spacing: 2px;
    }

    .table-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-container {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        background-color: rgba(0,0,0,0.25);
    }

    #form-position {
        position: absolute;
        z-index: 4 !important;
    }

</style>