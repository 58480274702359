<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <v-data-table
      :loading="loading"
      v-model:items-per-page-options="options"
      :fixed-header="true"
      :headers="headers"
      :items="this.stampanti"
      style="
        width: 1100px;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        max-height: 475px;
        margin-top: 20px;
      "
    >
    </v-data-table>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
import Swal from "sweetalert2";
const axios = require("axios");
const { DateTime } = require("luxon");
export default {
  name: "StampantiTable",

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  data() {
    return {
      headers: [
        { title: "ID", value: "_id", sortable: true },
        { title: "NOME", value: "name", sortable: true },
        { title: "ADDRESS", value: "address", sortable: true },
      ],
      loading: true,
      stampanti: [],
      options: [10, 20, 30, 40, 50],
    };
  },

  mounted() {
    this.fetchStampanti();
  },

  methods: {
    async fetchStampanti() {
      this.loading = true;
      const apiUrl = `${this.$apiBaseUrl}/printer`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.get(apiUrl, config);
        console.log("Response:", response.data);
        this.stampanti = response.data;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento delle stampanti`,
          icon: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
  width: fit-content;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-input {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}
</style>
