import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import UtentiView from "../views/UtentiView.vue";
import ColliBloccatiView from "../views/ColliBloccatiView.vue";
import ColliEntrataView from "../views/ColliEntrataView.vue";
import ColliUscitaView from "../views/ColliUscitaView.vue";
import BancaliUscitaView from "../views/BancaliUscitaView.vue";
import OrdiniView from "../views/OrdiniView.vue";
import ResiView from "../views/ResiView.vue";
import ChiusureGiornaliereOperatoreView from "../views/ChiusureGiornaliereOperatoreView.vue";
import ChiusureGiornaliereAdminView from "../views/ChiusureGiornaliereAdminView.vue";
import StampantiiView from "../views/StampantiiView.vue";
import ForbiddenView from "../views/ForbiddenView.vue";
import MobileScanView from "../views/MobileScanView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: ForbiddenView,
    beforeEnter: (to, from, next) => {
      let toPage = localStorage.getItem("toPage");
      if (store.state.global.authorized && toPage && toPage != undefined) {
        localStorage.removeItem("toPage");
        next(toPage);
      }
      next();
    },
  },
  {
    path: "/utenti",
    name: "utenti",
    component: UtentiView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/colli-bloccati",
    name: "colli-bloccati",
    component: ColliBloccatiView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/colli-in-entrata",
    name: "colli-in-entrata",
    component: ColliEntrataView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/colli-in-uscita",
    name: "colli-in-uscita",
    component: ColliUscitaView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/bancali-in-uscita",
    name: "bancali-uscita",
    component: BancaliUscitaView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/ordini",
    name: "ordini",
    component: OrdiniView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/resi",
    name: "resi",
    component: ResiView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/stampanti",
    name: "stampanti",
    component: StampantiiView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/mie-chiusure",
    name: "mie-chiusure",
    component: ChiusureGiornaliereOperatoreView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_OPERATORE")) {
        next();
      } else {
        if (!store.state.global.authorized) {
          console.log(from);
          console.log(to);
          localStorage.setItem("toPage", to.fullPath);
        }
        next("/forbidden");
      }
    },
  },
  {
    path: "/chiusure-giornaliere",
    name: "chiusure-giornaliere",
    component: ChiusureGiornaliereAdminView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/scansiona-colli",
    name: "scansiona-colli",
    component: MobileScanView,
    beforeEnter: (to, from, next) => {
      if (
        store.state.global.userData.roles.includes("ROLE_OPERATORE") ||
        store.state.global.userData.roles.includes("ROLE_ADMIN")
      ) {
        next();
      } else {
        next("/forbidden");
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
