<template>
    <div>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px; margin-bottom: 20px">
            <div class="filters-actions-container">
                <div class="actions">
                    <v-btn height="48" variant="outlined" @click="showCreateForm">CREA NUOVO UTENTE</v-btn>
                </div>
                <div class="filters">
                    <v-text-field
                        density="comfortable"
                        variant="outlined"
                        label="CERCA UTENTE"
                        append-inner-icon="mdi-magnify"
                        hide-details
                        style="width: 200px !important;"
                        v-model="searchingUtente.text"
                        @input="searchUtente"
                    ></v-text-field>
                    <v-btn variant="outlined" height="48" class="bg-red" @click="resetFiltri">RESET FILTRI</v-btn>
                </div>
            </div>
        </div>
        <v-data-table
            :fixed-header="true"
            v-model:items-per-page-options="options"
            :loading="loading"
            :headers="headers"
            :items="this.filteredUtenti"
            style="width: 1100px; border: 1px solid #D0D0D0; border-radius: 5px; max-height: 475px;"
        >
            <template v-slot:item.firstName="{ item }">
                {{ item.firstName + " " + item.lastName }}
            </template>
            <template v-slot:item.id="{ item }">
                <div class="user-actions-container">
                    <v-btn variant="outlined" @click="showEditForm(item)">MODIFICA</v-btn>
                    <v-btn @click="showChangeAttivo(item, 'si')" variant="outlined" v-if="item.ruolo === ''">
                        ATTIVA
                    </v-btn>
                    <v-btn @click="showChangeAttivo(item, 'no')" variant="outlined" v-else>
                        DISATTIVA
                    </v-btn>
                    <v-btn height="40" variant="outlined" @click="showResetPwdForm(item)">RESET PWD</v-btn>
                </div>
            </template>
            <template v-slot:item.ruolo="{ item }">
                <span v-if="item.ruolo === 'ROLE_ADMIN'">ADMIN</span>
                <span v-if="item.ruolo === 'ROLE_OPERATORE'">OPERATORE</span>
                <span v-if="item.ruolo === ''">NESSUNO</span>
            </template>
            <template v-slot:item.attivo="{ item }">
                <v-chip v-if="item.ruolo != ''" color="green" variant="flat">
                    SI
                </v-chip>
                <v-chip v-else color="red" variant="flat">
                    NO
                </v-chip>
            </template>
        </v-data-table>
    </div style="margin-bottom: 20px;">
</template>

<script>
    import { useStore } from 'vuex';
    export default {
        name: "UtentiTable",

        props: {
            utenti: {
                type: Array,
            },
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        data() {
            return {
                headers: [
                    { title: "EMAIL", value: 'email', sortable: true },
                    { title: "NOME e COGNOME", value: 'firstName', sortable: true },
                    { title: "RUOLO", value: 'ruolo', sortable: true },
                    { title: "ATTIVO", value: 'attivo', sortable: true },
                    { title: 'AZIONI MODIFICA UTENTE', value: 'id', sortable: false },
                ],
                searchingUtente: {
                    text: ""
                },
                filteredUtenti: [],
                loading: true,
                options: [10, 20, 30, 40, 50]
            }
        },

        methods: {
            searchUtente() {
                this.loading = true
                const searchTerm = this.searchingUtente.text.trim().toLowerCase();
                if (searchTerm) {
                    this.filteredUtenti = this.utenti.filter((utente) => {
                        const email = utente.email.toLowerCase();
                        const name = utente.firstName.toLowerCase() + " " + utente.lastName.toLowerCase();
                        return (
                            email.includes(searchTerm) || name.includes(searchTerm)
                        );
                    });
                } else {
                    console.log(this.utenti);
                    this.filteredUtenti = [...this.utenti];
                }
                setTimeout(() => {
                    this.loading = false
                }, 300);
            },
            resetFiltri() {
                this.searchingUtente.text = ""
                this.searchUtente()
            },
            showEditForm(utente) {
                this.store.dispatch("changeBodyClass", "formOpen")
                this.store.dispatch('showEditUtenteForm', utente)
            },
            showCreateForm() {
                this.store.dispatch("changeBodyClass", "formOpen")
                this.store.dispatch('showCreateUtenteForm')
            },
            showResetPwdForm(utente) {
                this.store.dispatch("changeBodyClass", "formOpen")
                this.store.dispatch('showResetPwd', utente)
            },
            showChangeAttivo(utente, attivo) {
                this.store.dispatch("changeBodyClass", "formOpen")
                this.store.dispatch('showChangeAttivo', [utente, attivo])
            },
        },

        watch: {
            utenti: function(newVal) {
                console.log(newVal, "newVal");
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    this.searchUtente()
                }, 300);
            }
        }
       
    }
</script>

<style scoped>
    .filters-actions-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1100px;
    }

    .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: fit-content;
    }

    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .v-text-field--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .v-field--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .user-actions-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .v-card-title {
        font-size: 2rem;
    }

    .warning-text {
        font-size: 1.2rem;
        text-align: center;
        width: 400px;
    }

</style>