<template>
    <div>
        <v-card title="CREA NUOVO UTENTE">
            <v-card-text>
                <v-container>
                    <v-text-field
                        style="width: 300px"
                        label="EMAIL"
                        required
                        variant="outlined"
                        v-model="this.email"
                    ></v-text-field>
                    <v-text-field
                        style="width: 300px"
                        label="NOME"
                        required
                        variant="outlined"
                        v-model="this.nomeUtente"
                    ></v-text-field>
                    <v-text-field
                        style="width: 300px"
                        label="COGNOME"
                        required
                        variant="outlined"
                        v-model="this.cognomeUtente"
                    ></v-text-field>
                    <v-text-field
                        label="PASSWORD"
                        type="password"
                        required
                        variant="outlined"
                        v-model="this.password"
                    ></v-text-field>
                    <v-select
                        :items="['ADMIN', 'OPERATORE']"
                        label="RUOLO"
                        variant="outlined"
                        v-model="this.ruolo"
                        required
                        style="z-index: 2;"
                    ></v-select>
                </v-container>
                <small style="color: red">{{ this.errorMsg }}</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="closeForm"
                >
                    CHIUDI
                </v-btn>
                <v-btn
                    color="black"
                    variant="outlined"
                    @click="createUtente"
                    class="bg-green"
                >
                    INVIA
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    export default {
        name: "CreateUtenteForm",

        data() {
            return {
                ruolo: null,
                nomeUtente: "",
                cognomeUtente: "",
                email: "",
                password: "",
                attivo: "si",
                errorMsg: "",
            }
        },

        setup() {
            const store = useStore();
            return {
                store,
            };
        },

        methods: {
            closeForm() {
                this.store.dispatch("changeBodyClass", "")
                this.resetAll()
                this.store.dispatch('closeCreateUtenteForm')
            },
            createUtente() {
                if(this.nomeUtente !== '' && this.ruolo !== null && this.password !== '' && this.email !== '' && this.cognomeUtente !== ''){
                    let ruoloVero = ""
                    if (this.ruolo === "OPERATORE") {
                        ruoloVero = "ROLE_OPERATORE"
                    } else if (this.ruolo === "ADMIN") {
                        ruoloVero = "ROLE_ADMIN"
                    }
                    const newUtente = {
                        email: this.email,
                        // attivo: this.attivo,
                        firstName: this.nomeUtente,
                        lastName: this.cognomeUtente,
                        ruolo: ruoloVero,
                        password: this.password,
                    }
                    this.$emit('create-utente', newUtente)
                    this.errorMsg = ''
                } else {
                    this.errorMsg = 'Compila tutti i campi'
                }
            },
            resetAll() {
                this.password = ''
                this.nomeUtente = ''
                this.cognomeUtente = ''
                this.email = ''
                this.ruolo = null
                this.errorMsg = ''
            }
        },

        mounted() {
            this.resetAll()
        },

    }
</script>

<style scoped>
    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
    
    .v-list-item--active {
        background-color: #2196f3;
        color: white;
    }
    
</style>