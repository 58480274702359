<template>
  <v-card width="1050">
    <v-card-title>DETTAGLI RESO N° {{ this.numReso }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-data-table
          v-model:sort-by="sortBy"
          v-model:items-per-page-options="options"
          :fixed-header="true"
          :items="this.contenutoReso"
          :headers="this.headers"
          style="
            border: 1px solid #d0d0d0;
            border-radius: 5px;
            max-height: 475px;
          "
        >
          <template v-slot:header.customerTrackingNumbers>
            <v-tooltip location="top" text="CUSTOMER TRACKING NUMBER">
              <template v-slot:activator="{ props }">
                <span v-bind="props">CTN.</span>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:header.supplierTrackingNumbers>
            <v-tooltip location="top" text="SUPPLIER TRACKING NUMBER">
              <template v-slot:activator="{ props }">
                <span v-bind="props">STN.</span>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:item.hold="{ item }">
            <v-chip v-if="item.hold" color="red" variant="flat"> SI </v-chip>
            <v-chip v-else color="green" variant="flat"> NO </v-chip>
          </template>
          <template v-slot:item.updatedDate="{ item }">
            <p v-if="item.updatedDate === null || item.updatedDate === 'null'">
              NESSUNO
            </p>
            <p v-else>{{ this.formattaDataOra(item.updatedDate) }}</p>
          </template>
          <template v-slot:item.customerTrackingNumbers="{ item }">
            <ul>
              <li v-if="item.customerTrackingNumbers.length === 0">NESSUNO</li>
              <li v-else v-for="ctm in item.customerTrackingNumbers">
                {{ ctm }}
              </li>
            </ul>
          </template>
          <template v-slot:item.supplierTrackingNumbers="{ item }">
            <ul>
              <li v-if="item.supplierTrackingNumbers.length === 0">NESSUNO</li>
              <li v-else v-for="stm in item.supplierTrackingNumbers">
                {{ stm }}
              </li>
            </ul>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="black" variant="outlined" @click="closeDetails">
        CHIUDI
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useStore } from "vuex";
const { DateTime } = require("luxon");
export default {
  name: "ContenutoReso",

  props: {
    contenutoReso: {
      type: Array,
    },
    numReso: {
      type: Number,
    },
  },

  data() {
    return {
      sortBy: [{ key: "updatedDate", order: "asc" }],
      headers: [
        { title: "NUMERO ITEM RESO", value: "id", sortable: true },
        { title: "QC", value: "qcStatusId", sortable: true },
        { title: "FERMO", value: "hold", sortable: true },
        { title: "ULTIMO AGGIORNAMENTO", value: "updatedDate", sortable: true },
      ],
      options: [10, 20, 30, 40, 50],
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  methods: {
    closeDetails() {
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeContenutoReso");
    },

    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date)
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
  },

  mounted() {},
};
</script>

<style scoped></style>
