<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; row-gap: 20px; text-align: center;">
    <p class="title">BENVENUTO</p>
    <p class="subtitle" v-if="this.store.state.global.authorized">{{ this.store.state.global.userData.firstName }} {{ this.store.state.global.userData.lastName }}</p>
    <v-btn v-else variant="outlined" class="bg-green" @click="login" size="x-large">EFFETTUA IL LOGIN</v-btn>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  export default {
    name: 'HomeView',

    components: {
    },

    setup() {
        const store = useStore();
        return {
            store,
        };
    },

    methods: {
      login () {
        if (this.$keycloak) {
            this.$keycloak.login();
        } else {
            console.error('Keycloak not initialized yet.');
            // Handle the situation accordingly, e.g., redirect to a login page.
        }
      },
    },
  };
</script>

<style scoped>
  .title {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }

  .subtitle {
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }
</style>