/* eslint-disable no-console */
import { register } from "register-service-worker";
import Swal from "sweetalert2";

if (process.env.NODE_ENV === "production") {
  console.log(self);
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker");
    },
    registered() {
      // Swal.fire("Service worker has been registered.");
      console.log("Service Worker registered");
    },
    offline() {
      Swal.fire({
        title: "No Internet Connection",
        text: "App is running in offline mode.",
        icon: "warning",
      });
      console.log("offline");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
