<template>
  <div>
    <v-card
      min-width="500px"
      title="ATTIVAZIONE UTENTE"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        row-gap: 50px;
        padding-top: 30px;
      "
      v-if="this.attivo == 'si'"
    >
      <div class="warning-text-container">
        <p class="warning-text">
          SELEZIONA IL RUOLO PER L'UTENTE:<br /><span class="nome-utente">{{
            this.utente.email
          }}</span>
        </p>
      </div>
      <div>
        <v-select
          :items="['ADMIN', 'OPERATORE']"
          label="RUOLO"
          variant="outlined"
          v-model="this.prevRuolo"
          required
          width="200px"
          style="width: 200px"
        ></v-select>
        <small style="color: red">{{ this.errorMessage }}</small>
      </div>
      <v-card-actions style="column-gap: 50px">
        <v-btn
          text="INVIA"
          variant="outlined"
          class="bg-green"
          width="100"
          height="50"
          @click="changeAttivo"
        >
        </v-btn>
        <v-btn
          variant="outlined"
          class="bg-red"
          width="100"
          height="50"
          text="CHIUDI"
          @click="closeChangeAttivo"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      min-width="500px"
      title="ATTENZIONE"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        row-gap: 50px;
        padding-top: 30px;
      "
      v-if="this.attivo == 'no'"
    >
      <div class="warning-text-container">
        <p class="warning-text">
          SEI SICURO DI VOLER DISATTIVARE L'UTENTE:<br /><span
            class="nome-utente"
            >{{ this.utente.email }}</span
          >
        </p>
      </div>
      <v-card-actions style="column-gap: 50px">
        <v-btn
          text="SI"
          variant="outlined"
          class="bg-green"
          width="100"
          height="50"
          @click="changeAttivo"
        >
        </v-btn>
        <v-btn
          variant="outlined"
          class="bg-red"
          width="100"
          height="50"
          text="NO"
          @click="closeChangeAttivo"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "ChangeAttivo",

  data() {
    return {
      prevRuolo: "",
      errorMessage: "",
    };
  },

  setup() {
    const store = useStore();
    return {
      store,
    };
  },

  props: {
    utente: {
      type: Object,
    },
    attivo: {
      type: String,
    },
  },

  mounted() {
    if (this.utente.ruolo === "ROLE_ADMIN") {
      this.prevRuolo = "ADMIN";
    } else if (this.utente.ruolo === "ROLE_OPERATORE") {
      this.prevRuolo = "OPERATORE";
    }
  },

  methods: {
    changeAttivo() {
      if (this.prevRuolo === "" || this.prevRuolo === null) {
        this.errorMessage = "Seleziona un ruolo";
      } else {
        let newRuolo = "";
        if (this.prevRuolo === "ADMIN") {
          newRuolo = "ROLE_ADMIN";
        } else if (this.prevRuolo === "OPERATORE") {
          newRuolo = "ROLE_OPERATORE";
        }
        this.prevRuolo = "";
        this.errorMessage = "";
        this.$emit("change-attivo", this.utente, this.attivo, newRuolo);
      }
    },
    closeChangeAttivo() {
      this.store.dispatch("changeBodyClass", "");
      this.store.dispatch("closeChangeAttivo");
    },
  },
};
</script>

<style scoped>
.warning-text {
  text-align: center;
  font-size: 1.2rem;
  width: 400px;
}

.nome-utente {
  font-weight: 600;
  font-size: 1.5rem;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}
</style>
