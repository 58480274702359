<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <div class="filters-actions-container">
        <div class="actions" style="align-self: flex-start">
          <v-btn
            height="48"
            width="200"
            variant="outlined"
            color="red"
            @click="showBloccaForm"
            >BLOCCA COLLO</v-btn
          >
        </div>
        <div class="filters">
          <v-btn
            icon="mdi-reload"
            color="blue"
            size="small"
            @click="reloadData"
          ></v-btn>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA UTENTE"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important"
            v-model="searchingUtente.text"
            @input=""
          ></v-text-field>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: end;
              row-gap: 5px;
            "
          >
            <v-dialog width="375px">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="outlined"
                  text="DATA BLOCCO"
                  height="48px"
                  width="200"
                ></v-btn>
              </template>
              <template v-slot:default="{ isActive }">
                <v-card title="SELEZIONA DATA BLOCCO">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-date-picker
                      style="border: 1px solid #d0d0d0 !important"
                      v-model="this.dataBlocco"
                    ></v-date-picker>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text="CANCELLA"
                      @click="this.dataBlocco = null"
                      class="bg-red"
                      variant="outlined"
                    ></v-btn
                    ><v-btn
                      text="INVIA"
                      @click="
                        () => {
                          searchCollo();
                          isActive.value = false;
                        }
                      "
                      class="bg-green"
                      variant="outlined"
                    ></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <small>{{ this.formatDate(this.dataBlocco) }}</small>
          </div>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA LDV"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important"
            v-model="searchingCollo.text"
            @input="searchCollo"
          ></v-text-field>
          <v-btn
            variant="outlined"
            height="48"
            class="bg-red"
            @click="resetFiltri"
            >RESET FILTRI</v-btn
          >
        </div>
      </div>
    </div>
    <div style="width: 95%">
      <v-data-table
        :loading="loading"
        v-model:items-per-page-options="options"
        v-model:sort-by="sortBy"
        :fixed-header="true"
        :headers="headers"
        :items="this.filteredColli"
        style="
          width: 100%;
          border: 1px solid #d0d0d0;
          border-radius: 5px;
          max-height: 475px;
        "
      >
        <template v-slot:header.discordanze>
          <v-tooltip location="top" text="DISCORDANZE">
            <template v-slot:activator="{ props }">
              <span v-bind="props">DIS.</span>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.discordanze="{ item }">
          <v-chip v-if="item.discordanze != 0" color="red" variant="flat">
            {{ item.discordanze }}
          </v-chip>
          <v-chip v-else color="green" variant="flat">
            {{ item.discordanze }}
          </v-chip>
        </template>
        <template v-slot:item.emailAggiornatore="{ item }">
          <p
            v-if="
              item.emailAggiornatore === null ||
              item.emailAggiornatore === 'null'
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ item.emailAggiornatore }}</p>
        </template>
        <template v-slot:item.data_creazione="{ item }">
          {{ this.formattaDataOra(item.data_creazione) }}
        </template>
        <template v-slot:item.data_aggiornamento="{ item }">
          <p
            v-if="
              item.data_aggiornamento === null ||
              item.data_aggiornamento === 'null'
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ this.formattaDataOra(item.data_aggiornamento) }}</p>
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn variant="outlined" @click="showSbloccaConfirm(item)"
            >SBLOCCA</v-btn
          >
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
const { DateTime } = require("luxon");
export default {
  name: "ColliBloccatiTable",

  props: {
    colli: {
      type: Array,
    },
  },

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  data() {
    return {
      searchingUtente: {
        text: "",
      },
      sortBy: [{ key: "data_aggiornamento", order: "asc" }],
      headers: [
        { title: "CODICE COLLO", value: "ldv", sortable: true },
        { title: "DATA BLOCCO", value: "data_creazione", sortable: false },
        { title: "DISCORDANZE", value: "discordanze", sortable: true },
        {
          title: "N° PEZZI",
          value: "numeroPezzi",
          sortable: false,
          align: "center",
        },
        { title: "STATO", value: "stato", sortable: false },
        {
          title: "ULTIMO AGGIORNAMENTO",
          value: "data_aggiornamento",
          sortable: true,
        },
        {
          title: "AUTORE AGGIORNAMENTO",
          value: "emailAggiornatore",
          sortable: true,
        },
        { title: "", value: "id", sortable: false },
      ],
      searchingCollo: {
        text: "",
      },
      filteredColli: [],
      loading: true,
      dataBlocco: null,
      options: [10, 20, 30, 40, 50],
    };
  },

  methods: {
    reloadData() {
      this.loading = true;
      this.$emit("reload-data");
    },
    searchCollo() {
      this.loading = true;
      const ricerca = {
        searchingCollo: {
          text: this.searchingCollo.text,
        },
        dataBlocco: this.dataBlocco,
        userMail:
          this.searchingUtente.text.trim().toLowerCase() == ""
            ? null
            : this.searchingUtente.text.trim().toLowerCase(),
      };
      this.filteredColli = this.colli.filter((collo) =>
        this.filtraColli(collo, ricerca)
      );
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    filtraColli(collo, ricerca) {
      const dataBlocco = new Date(
        collo.data_creazione.split(" / ").reverse().join("-")
      );
      dataBlocco.setHours(0, 0, 0, 0);
      console.log(ricerca.dataBlocco);
      let dataBloccoRicerca = null;
      if (ricerca.dataBlocco !== null) {
        dataBloccoRicerca = new Date(ricerca.dataBlocco);
        dataBloccoRicerca.setHours(0, 0, 0, 0);
      }
      console.log(dataBlocco);
      console.log(dataBloccoRicerca);
      console.log(dataBlocco == dataBloccoRicerca);
      return (
        (ricerca.searchingCollo.text === "" ||
          collo.ldv.includes(ricerca.searchingCollo.text)) &&
        (dataBloccoRicerca === null ||
          dataBlocco.getTime() === dataBloccoRicerca.getTime()) &&
        (ricerca.userMail.trim().toLowerCase() == "" ||
          collo.emailAggiornatore.includes(
            ricerca.userMail.trim().toLowerCase()
          ))
      );
    },
    resetFiltri() {
      this.searchingCollo.text = "";
      this.searchingUtente.text = "";
      this.dataBlocco = null;
      this.searchCollo();
    },
    showSbloccaConfirm(collo) {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showSbloccaColloConfirm", collo);
    },
    showBloccaForm() {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showBloccaColloForm");
    },
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date + "Z")
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
  },

  watch: {
    colli: function (newVal) {
      console.log(newVal, "newVal");
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.searchCollo();
      }, 300);
    },
  },
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10px;
  width: fit-content;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}
</style>
